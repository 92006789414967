import axiosInstanceNl from "@/api/axiosInstanceNl";
import axiosInstance from "@/api/axiosInstance";

/**
 * 공통코드 조회
 * @param String comGrpCdId 
 * @returns 
 */
export const selectCodeListApi = (comGrpCdId) => {
	return axiosInstanceNl.get('/code/selectCodeList.json', { params: { comGrpCdId: comGrpCdId }});
};

/**
 * 서버 시간 조회
 */
export const getNowDateTimeApi = () => {
	return axiosInstanceNl.get('/common/getNowDateTime.json');
}


/**
 * 암호화용 공개키 요청
 * @returns 
 */
export const getPublicKey = () => {
	return axiosInstance.get('/common/getPublicKey.json');
}

/**
 * 업로드 가능 여부 체크
 * @returns 
 */
export const checkAllowedUploadApi = () => {
	return axiosInstance.get("/common/checkAllowedUpload.json");
};