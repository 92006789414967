
/**
 * 입찰제한 라우터 설정
 *
 * @author bjy
 * @since 2023-04-10
 * @version 1.0
 */
export default [
    {
        path: '/annc/restrict/restrictList',
        name: 'UserRestrictList',
        component: () => import('@/views/annc/restrict/UserRestrictList.vue'),
        meta: { contentTitle: `입찰제한 사용자 목록`, menu: 'UserRestrict' },
    },
    {
        path: '/annc/restrict/restrictDetail',
        name: 'UserRestrictDetail',
        component: () => import('@/views/annc/restrict/UserRestrictDetail.vue'),
        meta: { contentTitle: `입찰제한 사용자 상세`, menu: 'UserRestrict' },
        props: (route) => route.query
    },
    {
        path: '/annc/restrict/restrictRegist',
        name: 'UserRestrictRegist',
        component: () => import('@/views/annc/restrict/UserRestrictRegist.vue'),
        meta: { contentTitle: `입찰제한 사용자 등록`, menu: 'UserRestrict' },
        props: (route) => route.query
    },
    {
        path: '/annc/restrict/restrictUpdate',
        name: 'UserRestrictUpdate',
        component: () => import('@/views/annc/restrict/UserRestrictUpdate.vue'),
        meta: { contentTitle: `입찰제한 사용자 수정`, menu: 'UserRestrict' },
        props: (route) => route.query
    },
];
