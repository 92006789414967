/**
 * 회원가입 라우터 설정
 * 
 * 
 * @author ldw
 * @since 2023-03-24
 * @version 1.0
 */
export default [
    {
        path: '/user/join/joinUserChoice',
        name: 'JoinUserChoice',
        component: () =>import('@/views/user/join/JoinUserChoice.vue'),
        meta: { contentTitle: '회원선택' , layout : 'default' },
        props: (route) => route.query
    },
    {
        path: '/user/join/joinUserTerm',
        name: 'JoinUserTerm',
        component: () =>import('@/views/user/join/JoinUserTerm.vue'),
        meta: { contentTitle: '이용약관' , layout : 'default' },
        props: (route) => route.query
    },
    {
        path: '/user/join/CompanyCheck',
        name: 'CompanyCheck',
        component: () =>import('@/views/user/join/CompanyCheck.vue'),
        meta: { contentTitle: '등록여부확인' , layout : 'default'},
        props: (route) => route.query
    },
    {
        path: '/user/join/joinUserInRegist',
        name: 'JoinUserInRegist',
        component: () =>import('@/views/user/join/JoinUserInRegist.vue'),
        meta: { contentTitle: '발행사 사용자 등록' , layout : 'default'},
        props: (route) => route.query
    },
    {
        path: '/user/join/joinUserOutRegist',
        name: 'JoinUserOutRegist',
        component: () =>import('@/views/user/join/JoinUserOutRegist.vue'),
        meta: { contentTitle: '회원사 사용자 등록' , layout : 'default'},
        props: (route) => route.query
    },
    {
        path: '/user/join/joinUserOutCompRegist',
        name: 'JoinUserOutCompRegist',
        component: () =>import('@/views/user/join/JoinUserOutCompRegist.vue'),
        meta: { contentTitle: '회원사 사용자 등록' , layout : 'default'},
        props: (route) => route.query
    }
];