/**
 * 에러 라우터 설정
 * 
 * @author pjg
 * @since 2023-03-17
 * @version 1.0
 */
export default [
	{
		path: '/errorView',
		name: 'ErrorView',
		component: () => import('@/views/ErrorView.vue'),
		meta: { layout: 'default' },
		props: (route) => ({ errorCode: route.query.errorCode })
	},
	{
		path: '/:pathMatch(.*)*',
		name: 'NotFound', // error code 404
		component: () => import('@/views/ErrorView.vue'),
		meta: { layout: 'default' },
		props: () => ({ errorCode: 'ERR_NOT_FOUND' })
	}
];