/**
 * 회원가입 라우터 설정
 * 
 * @author ldw
 * @since 2023-03-24
 * @version 1.0
 */
export default [
    {
        path: '/user/find/findUser',
        name: 'FindUser',
        component: () =>import('@/views/user/find/FindUser.vue'),
        meta: { contentTitle: '아이디찾기' , layout : 'default'}  
    },
    {
        path: '/user/find/findUserForId',
        name: 'FindUserForld',
        component: () =>import('@/views/user/find/FindUserForld.vue'),
        meta: { contentTitle: '아이디찾기' , layout : 'default'}  
    },
    {
        path: '/member/findUsreCheckId',
        name: 'FindUserCheckId',
        component: () =>import('@/views/user/find/FindUserCheckId.vue'),
        meta: { contentTitle: '아이디찾기' , layout : 'default'}  ,
        props: route => route.query
    },
    {
        path: '/user/find/findPwd',
        name: 'FindPwd',
        component: () =>import('@/views/user/find/FindPwd.vue'),
        meta: { contentTitle: '비밀번호찾기' , layout : 'default'}  
    },
    {
        path: '/user/find/findPwdById',
        name: 'FindPwdById',
        component: () =>import('@/views/user/find/FindPwdById.vue'),
        meta: { contentTitle: '비밀번호찾기' , layout : 'default'}  
    },
    {
        path: '/user/find/findPwdByCert',
        name: 'FindPwdByCert',
        component: () =>import('@/views/user/find/FindPwdByCert.vue'),
        meta: { contentTitle: '비밀번호찾기' , layout : 'default'}  
    },
    {
        path: '/user/find/findPwdReset',
        name: 'FindPwdReset',
        component: () =>import('@/views/user/find/FindPwdReset.vue'),
        meta: { contentTitle: '비밀번호찾기' , layout : 'default'}  
    },
    {
        path: '/user/find/SelfCert',
        name: 'SelfCert',
        component: () =>import('@/views/user/find/SelfCert.vue'),
        meta: { contentTitle: '본인확인 인증' , layout : 'default'} 
    }
]