/**
 * 기금운용 입찰공고 라우터 설정
 * 
 * @author bjy
 * @since 2023-04-21
 * @version 1.0
 */
export default [
	{
		path: '/annc/fund/anncFundUsrList',
		name: 'AnncFundUsrList',
		component: () => import('@/views/annc/fund/usr/AnncFundUsrList.vue'),
		meta: { contentTitle: '기금운용 입찰공고 목록', menu: 'AnncFundUsr' }
	},
	{
		path: '/annc/fund/anncFundUsrDetail',
		name: 'AnncFundUsrDetail',
		component: () => import('@/views/annc/fund/usr/AnncFundUsrDetail.vue'),
		meta: { contentTitle: '기금운용 입찰공고 상세', menu: 'AnncFundUsr' },
		props: route => route.query
	},
];