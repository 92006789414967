/**
 * 서버 api 호출 No Loading 인스턴스 (호출 중 loading 없음)
 *
 * @author pjg
 * @since 2023.04.26
 * @version 1.0
 */
import axios from "axios";
import store from '@/store';
import comMessage from '@/common/comMessage';
import comConstant from "@/common/comConstant";

/**
 * axios 인스턴스(기본 설청 추가)
 */
const axiosInstanceNl = axios.create({
	baseURL: process.env.VUE_APP_API_URL + process.env.VUE_APP_API_PREFIX,
	timeout: 10000,
	headers: { 
		'Content-Type': 'application/json'
	},
	withCredentials: true
});

/**
 * 요청 인터셉터
 */
axiosInstanceNl.interceptors.request.use(
	async (config) => {
		if(store.getters.getLoginUser?.jwtToken) {
			config.headers.Authorization = store.getters.getLoginUser?.jwtToken;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

/**
 * 응답 인터셉터
 */
axiosInstanceNl.interceptors.response.use(
	(response) => {

		if (response.data.code != 0) {

			if (response.data.code != 0) {
				if (response.data?.code == comConstant.jwt.expired) { // 토큰 만료
					store.commit('setShowTokenExpiredAlert', true);
					return false;
					//return Promise.reject({ code: response.data.code, message: response.data.message });
				} else if (response.data?.code == comConstant.jwt.newGeneration) { // access토큰 재발행
					store.commit('setLoginUser', {...store.getters.getLoginUser, jwtToken: response.data.message}); // store 변경
					response.config.headers.Authorization = response.data.message; // token변경
					return axios(response.config); // api 재요청
				} else {
					return Promise.reject({ code: response.data.code, message: response.data.message });
				}
			}
		}

		return response;
	},
	async (error) => {
		const httpErrorMessage = comMessage.HTTP_STATUS[error.code];
		
		if (httpErrorMessage) {
			error.message = httpErrorMessage;
		}

		return Promise.reject(error);
	}
);

export default axiosInstanceNl;