<template>
	<div class="cal-head">
			<router-link v-if="reactiveValue.routerType == 'B'" :to="{ name: 'AnncBondUsrList' }" class="more-btn"/>
			<router-link v-else-if="reactiveValue.routerType == 'F'" :to="{ name: 'AnncFundUsrList' }" class="more-btn"/>
			<router-link v-else :to="{ name: 'AnncSchedule', query: { fromMainSchedule : reactiveValue.targetDate.getFullYear() 
																						+ objectUtil.zeroFill(reactiveValue.targetDate.getMonth() +1,2)
																						+ objectUtil.zeroFill(reactiveValue.targetDate.getDate(),2) } }" class="more-btn"/>
		<a href="#" class="bx-prev" @click="() => prevMonth()"></a>
		<strong>{{ `${reactiveValue.mainYm.substring(0, 4)}-${reactiveValue.mainYm.substring(4, 6)}` }}</strong>
		<a href="#" class="bx-next" @click="() => nextMonth()"></a>
	</div>
	<ul class="calendar">
		<li v-for="(dateInfo, i) in reactiveValue.searchedDates" :key="`date-${i}`">
			<span class="num" :class="{'red': dateInfo.day == 0, 'blue': dateInfo.day == 6, 'point': hasAnncData(dateInfo.date)}">
				{{ dateInfo.date }}
			</span>
		</li>
	</ul>
</template>

<script setup>
import { defineProps, reactive, computed, onMounted, defineExpose} from "vue";
import { objectUtil } from '@/common/comUtil';
import mainManager from "./../../views/main/modules/mainManager";
import { useStore } from 'vuex';
import comConstant from '@/common/comConstant';

const props = defineProps({
	searchYm: String,
	searchedDates: Array,
	systemType: String,
	anncTpCd: String
});

const state = computed(() => ({
	anncTpCd: props.anncTpCd,
    systemType: props.systemType,
}));

const store = useStore();

const reactiveValue = reactive({
	anncList:[],
	targetDate: new Date(),
    mainYm: props.searchYm,
    searchedDates: props.searchedDates,
	routerType:''
});

/**
 * 이전 월
 */
const prevMonth = () => {
	reactiveValue.targetDate = new Date(reactiveValue.targetDate.getFullYear(), reactiveValue.targetDate.getMonth() - 1, 1);
	reactiveValue.mainYm = `${reactiveValue.targetDate.getFullYear()}${objectUtil.zeroFill(reactiveValue.targetDate.getMonth() + 1, 2)}`;
	reactiveValue.searchedDates = scheduleParamDates(reactiveValue.mainYm);
	searchAnncData(false);
};

/**
 * 다음 월
 */
const nextMonth = () => {
	reactiveValue.targetDate = new Date(reactiveValue.targetDate.getFullYear(), reactiveValue.targetDate.getMonth() + 1, 1);
	reactiveValue.mainYm = `${reactiveValue.targetDate.getFullYear()}${objectUtil.zeroFill(reactiveValue.targetDate.getMonth() + 1, 2)}`;
	reactiveValue.searchedDates = scheduleParamDates(reactiveValue.mainYm);
	searchAnncData(false);
};

/**
 * 날짜 Day 리스트
 */
const scheduleParamDates = (mainYm) => {
	return mainManager.getCalendarDates(mainYm);
};


/**
 * 일정 검색
 */
const searchAnncData = async(from, mainData) => {
	if(from) {
		await mainManager.getCalendarData(reactiveValue, mainData.anncTpCd, mainData.systemType);
	} else {
		await mainManager.getCalendarData(reactiveValue, state.value.anncTpCd, state.value.systemType);
	}
};

/**
 * 일정 css 표시
 */
const hasAnncData = (date) => {
	let boolean = false;
	for (const key of Object.keys(reactiveValue.anncList)) {
        if (key == date) {
        	boolean = true;
        }
    }
	return boolean;
};

const setRouterType = () => {
	let anncTpCdList = store.getters.getLoginUser?.job?.map(jobInfo => jobInfo.jobTpCd);

	if( store.getters.isSystemAdmin || store.getters.isBondAdmin || store.getters.isFundAdmin1 || store.getters.isFundAdmin2 ) {
    	reactiveValue.routerType = 'N';
    } else {
		if(anncTpCdList?.includes(comConstant.jobType.BOND_POWER)
					||anncTpCdList?.includes(comConstant.jobType.BOND_SHORT)
					||anncTpCdList?.includes(comConstant.jobType.BOND_COMMERCIAL)
					||anncTpCdList?.includes(comConstant.jobType.BOND_BANK)) {
			reactiveValue.routerType = 'B';
		} else if(anncTpCdList?.includes(comConstant.jobType.FUND_WELFARE)
					&& anncTpCdList?.includes(comConstant.jobType.FUND_SCHOOL)){
			reactiveValue.routerType = 'F';
		}
	}
};

onMounted(async() => {
	searchAnncData();
	setRouterType();
});

/**
 * 외부 노출
 */
defineExpose({
    searchAnncData,
	reactiveValue
});
</script>
