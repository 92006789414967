<!--
	(공통) 화면 하단 영역
	
	@author pjg
	@since 2023.03.16
	@version 1.0
-->
<template>
    <div id="footer">
		<div class="inner">
			<img src="@/assets/images/foot_logo.png">
			<p>Copyright ⓒ 2005-2023 KEPCO. ICT운영처. All rights reserved.</p>
			<ul class="foot-link">
				<li><router-link class="f-c-point" :to="{ name: 'FooterPrivacy' }">개인정보처리방침</router-link></li>
				<li><router-link :to="{ name: 'FooterMail' }">이메일주소무단수집금지</router-link></li>
			</ul>
		</div>
	</div>
</template>