<!--
	(공통) 알림창
    Modal.vue를 기반으로 동작
	
	@author pjg
	@since 2023.03.16
	@version 1.0
-->
<template>
    <modal ref="baseModal" :width="'300px'">
        <template v-slot:header v-if="alertHeader !== null">
            <h3>{{ alertHeader }}</h3>
        </template>
        <template v-slot:body>
            <div class="max-height-400 overflow-y-auto">
                <p>로그인 유지시간이 종료되어 자동으로 로그아웃 처리됩니다.</p>
            </div>
        </template>
        <template v-slot:footer>
            <button type="button" class="btn-white" @click="close">확인</button>
        </template>
    </modal>
</template>

<script setup>
import Modal from './Modal.vue';
import { defineProps, defineExpose, ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import router from '@/router';

defineProps({
    alertHeader: { type: String, default: null }
});

const store = useStore();

const baseModal = ref(null);
const resulvePromise = ref(null);

const isOpen = computed(() => store.getters.getSetShowTokenExpiredAlert);

/**
 * alert 열기
 */
const show = () => {
    baseModal.value.open();
    router.push({ name: 'Main' });
    store.commit('setLoginUser', null);
    return new Promise((resolve) => {
        resulvePromise.value = resolve;
    });
};

/**
 * alert 닫기
 */
const close = () => {
    baseModal.value.close();
    resulvePromise.value(true);
    // 로그아웃 처리
    store.commit('setShowTokenExpiredAlert', false);
};

/**
 * 외부 노출
 */
defineExpose({
    show
});

watch(isOpen, (newState) => {
    if(newState) show();
});
</script>

<style scoped>
.modal-footer button {
    width: 60px;
    line-height: 24px;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    text-align: center;
    display: block;
    float: right;
    margin-right: 7px;
    color: #848484;
    font-size: 14px;
}
</style>