/**
 * 전력채 발행현황 라우터 설정
 * 
 * @author jjw
 * @since 2023-05-16
 * @version 1.0
 */
export default [
	{
		path: '/annc/anncBondPblsList',
		name: 'AnncBondPblsList',
		component: () => import('@/views/annc/bond/AnncBondPblsList.vue'),
		meta: { contentTitle: '전력채 발행현황', menu: 'AnncBondPbls' }
	}
];