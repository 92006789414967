import axiosInstance from "./axiosInstance";

/**
 * 로그인
 * @param Object loginParam
 * @returns
 */
export const loginUserApi = (loginParam) => {
	return axiosInstance.post('/login/loginUser.json', loginParam)
};

/**
 * sso로그인
 * @returns
 */
export const ssoLoginApi = () => {
	return axiosInstance.get('/login/ssoLogin.json')
};

/**
 * 로그아웃
 * @param Object checkCertNoParam
 * @returns
 */
export const logoutUserApi = () => {
	return axiosInstance.post('/login/logoutUser.json')
};

/**
 * 유저 등록
 * 
 * @param Object registUserParam
 * @returns
 */
export const registUserApi = (registUserParam) => {
	return axiosInstance.post('/user/join/registUser.json', registUserParam);
};

/**
 * 업체 등록
 * @param registCompanyParam
 * @returns
 */
export const registCompanyUserApi = (registParam) => {
    return axiosInstance.post('/user/join/registCompanyUser.json', registParam);
};

/**
 * 아이디 찾기
 * @param loginId
 * @returns
 */
export const checkUserByLoginIdApi = (loginId) => {
	return axiosInstance.get('/user/findUserByLoginId.json', { params: { loginId: loginId }})
};

/**
 * 사용자 목록 조회
 * @param Object searchUserParam
 * @returns
 */
export const selectUserListApi = (searchParam) => {
	return axiosInstance.get('/user/selectUserList.json', { params: searchParam });
};

/**
 * 사용자 회사 목록 조회
 * @param Object searchUserParam
 * @returns
 */
export const selectUserCompanyListApi = (searchParam) => {
	return axiosInstance.get('/user/selectUserCompanyList.json', { params: searchParam });
};

/**
 * 사용자조회
 * @param Object searchUserParam
 * @returns
 */
export const selectUserApi = (userId) => {
	return axiosInstance.get('/user/selectUser.json',{ params: { userId: userId }})
};

/**
 * 로그인 사용자 조회
 * @param Object searchUserParam
 * @returns
 */
export const selectUserForMyApi = (userId) => {
	return axiosInstance.get('/user/my/selectUser.json',{ params: { userId: userId }})
};

/**
 * 사용자 일부 정보 목록 조회
 * @param 
 * @returns
 */
export const selectUserInfoListApi = (searchParam) => {
	return axiosInstance.get('/user/selectUserInfoList.json', { params: searchParam })
};

/**
 * 사용자 수정
 * @param Object updateUserParam
 * @returns
 */
export const updateUserApi = (updateUserParam) => {
	return axiosInstance.post('/user/updateUser.json', updateUserParam)
};

/**
* 사용자 수정
* @param Object updateUserParam
* @returns
*/
export const updateUserMyInfoApi = (updateUserParam) => {
   return axiosInstance.post('/user/updateUserMyInfo.json', updateUserParam)
};

/**
 * 사용자 상태 수정
 * @param Object updateUserStatusParam
 * @returns
 */
export const updateUserStatusApi = (updateUserStatusParam) => {
	return axiosInstance.post('/user/updateUserStatus.json', updateUserStatusParam)
};

/**
 * 사용자 재직증명서 조회
 * @param 
 * @returns
 */
export const selectUserFileApi = (userFileKey) => {
	return axiosInstance.get('/file/user/selectUserFile.json', { params: userFileKey })
};

/**
 * 재직증명서 첨부파일 다운로드
 * @param {Object} downloadParam
 * @returns
 */
export const downloadUserFileApi = (downloadParam) => {
	return axiosInstance.get('/file/user/downloadUserFile.json', { params: downloadParam, responseType: 'blob' });
};

/**
 * 재직증명서 파일 업로드 - 등록
 * 
 * @param Object registUserParam
 * @returns
 */
export const registUserFileApi = (registUserParam) => {
	return axiosInstance.post('/file/user/join/uploadUserFile.json', registUserParam, { headers: { 'Content-Type': 'multipart/form-data' }});
};

/**
 * 재직증명서 파일 업로드 - 수정
 * 
 * @param Object registUserParam
 * @returns
 */
export const updateUserFileApi = (updateUserFileParam) => {
	return axiosInstance.post('/file/user/update/uploadUserFile.json', updateUserFileParam, { headers: { 'Content-Type': 'multipart/form-data' }});
};

/**
 * 비밀번호 변경
 * 
 * @param Object changePasswordParam
 * @returns
 */
export const changePasswordApi = (changePasswordParam) => {
	return axiosInstance.post('/user/changePassword.json', changePasswordParam);
};

/**
 * 비밀번호 변경
 * 
 * @param Object changePasswordParam
 * @returns
 */
export const sendCertNoForHpApi = (sendCertNoForHpParam) => {
	return axiosInstance.post('/user/sendCertNoForHp.json', sendCertNoForHpParam);
};

/**
 * 비밀번호 변경
 * 
 * @param Object changePasswordParam
 * @returns
 */
export const changeMphoneApi = (changeMphoneParam) => {
	return axiosInstance.post('/user/changeMphone.json', changeMphoneParam);
};

/**
 * 계정잠김해제
 * @param Object unlockUserParam
 * @returns
 */
export const unlockLoginApi = (unlockLoginParam) => {
	return axiosInstance.post('/user/unlockLogin.json', unlockLoginParam)
};

/**
 * 사용자 탈퇴
 * 
 * @param Object updateUserParam
 * @returns
 */
export const updateUserWithdrawalApi = (updateUserParam) => {
	return axiosInstance.post('/user/updateUserWithdrawal.json', updateUserParam);
};
