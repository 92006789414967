<!--
	(공통) 알림창
    Modal.vue를 기반으로 동작
	
	@author pjg
	@since 2023.03.16
	@version 1.0
-->
<template>
    <modal ref="baseModal" :width="width">
        <template v-slot:header v-if="alertHeader !== null">
            <h3>{{ alertHeader }}</h3>
        </template>
        <template v-slot:body>
            <div class="max-height-400 overflow-y-auto">
                <p v-for="(text, i) in textContent" :key="i">
                    <template v-if="objectUtil.notEmpty(text)">{{ text }}</template>
                    <template v-else><br></template>
                </p>
            </div>
        </template>
        <template v-slot:footer>
            <button type="button" class="btn-white" @click="close">{{ closeText }}</button>
        </template>
    </modal>
</template>

<script setup>
import Modal from './Modal.vue';
import { defineProps, defineExpose, ref } from 'vue';
import { objectUtil } from '@/common/comUtil';

defineProps({
    closeText: { type: String, default: '확인' },
    alertHeader: { type: String, default: null },
    width: { type: String, default: '300px' }
});

const baseModal = ref(null);
const resulvePromise = ref(null);
const textContent = ref([]);

/**
 * alert 열기
 */
const show = (messages) => {
    textContent.value = Array.isArray(messages) ? messages : [ messages ];
    baseModal.value.open();
    return new Promise((resolve) => {
        resulvePromise.value = resolve;
    });
};

/**
 * alert 닫기
 */
const close = () => {
    baseModal.value.close();
    resulvePromise.value(true);
};

/**
 * 외부 노출
 */
defineExpose({
    show
});
</script>

<style scoped>
.modal-footer button {
    width: 60px;
    line-height: 24px;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    text-align: center;
    display: block;
    float: right;
    margin-right: 7px;
    color: #848484;
    font-size: 14px;
}
</style>