<!-- eslint-disable vue/multi-word-component-names -->
<!--
	(나의정보) 화면 좌측 메뉴
	DB 메뉴테이블에서 따로 관리하지 않는 메뉴라서 별도 구현
	
	@author pjg
	@since 2023.05.22
	@version 1.0
-->
<template>
	<div id="lnb">
		<h2>{{ currentMenu?.menuNm }}</h2>
		<ul class="lnb-navi">
			<li v-for="subMenu in leftMenuList" :key="subMenu?.menuId">
				<router-link :to="{ path: (subMenu?.menuUrlAddr || '#') }" :class="{ on: (subMenu?.menuId == currentMenu?.menuId) }">
					{{ subMenu?.menuNm }}
				</router-link>
			</li>
		</ul>
	</div>
</template>

<script setup>
import { computed } from "vue";
import { useRouter } from "vue-router";
// eslint-disable-next-line no-unused-vars
import userRouter from '@/router/userRouter';
import { useStore } from "vuex";

const menuList = [
	{ menuId: 'MyUser', menuNm: '나의정보', menuUrlAddr: '' },
	{ menuId: 'MyCertMng', menuNm: '인증서관리', menuUrlAddr: '' }
];

const router = useRouter();
const store = useStore();

const currentMenu = computed(() => {
	let res = {};
	const currentMenuId = router.currentRoute?.value.meta.hiddenMenu;
	
	if (currentMenuId) {
		res = menuList.find(menu => menu.menuId == currentMenuId.replace(getUserType(), ''));
	}
	
	return res;
});

const leftMenuList = computed(() => {
	let res = menuList;

	// 나의 정보
	res[0].menuUrlAddr = getMyUserUrlAddr();

	// 인증서 관리
	const foundRoute = userRouter.find(route => route.meta.hiddenMenu == 'MyCertMng');
	res[1].menuUrlAddr = foundRoute ? foundRoute.path : '#';

	return res;
});

/**
 * 사용자 유형(내부/외부)
 */
const getUserType = () => {
	// 나의 정보
	if (store.getters.isMember) {
		return 'Out';
	} else if (store.getters.isAdmin || store.getters.isBondAdmin) {
		return 'In';
	} else {
		return '';
	}
}

/**
 * 나의 정보 접속 URL
 */
const getMyUserUrlAddr = () => {
	let foundRoute = null;

	if (store.getters.isMember) {
		// 회원사일 때
		const menuId = menuList[0].menuId + 'OutDetail';
		// 나의정보 상세를 고정으로 지정
		foundRoute = userRouter.find(route => menuId == route.name);

	} else if (store.getters.isAdmin || store.getters.isBondAdmin) {
		// 실무자 또는 관리자일 때
		const menuId = menuList[0].menuId + 'InDetail';
		// 나의정보 상세를 고정으로 지정
		foundRoute = userRouter.find(route => menuId == route.name);

	} else {
		// 확인불가일 때
		foundRoute = null;
	}

	return foundRoute ? foundRoute.path : '#';
}
</script>
