<!--
	(공통) 화면 상단 영역
	
	@author pjg
	@since 2023.03.16
	@version 1.0
-->
<template>
    <div id="header" class="header">
        <!--headerIn-->
        <div class="headerIn">
            <h1 class="logo"><router-link :to="{ name: 'Main' }">전력채기금운용시스템</router-link></h1>
            <div class="gnb">
                <ServerTimer/>
                <ul class="valign-t">
                    <!--로그인전-->
                    <template v-if="!isLogin">
                        <li><router-link class="btnLogin" :to="{ name: 'Login' }">로그인</router-link></li>
                        <li><router-link class="btnJoin" :to="{ name: 'JoinUserChoice' }">회원가입</router-link></li>
                    </template>
                    <!--로그인후-->
                    <template v-else>
                        <li>
                            <i class="fa fa-user-circle-o"></i>
                            <span class="userText">{{ ' ' + loginUser?.userNm }}님</span>
                        </li>
                        <li><button type="button" class="btnLogout" @click="moveMyPage($router)">나의정보</button></li>
                        <li><button type="button" class="btnLogout" @click="logout($router)">로그아웃</button></li>
                    </template>
                    <li><router-link class="btnSitemap" :to="{ name: 'SiteMap' }">사이트맵</router-link></li>
                    <li v-if="isLogin" class="userJobList ellipsis display-b" :title="loginUser.job?.map(jobInfo => jobInfo.jobTpNm).join(', ')">
                        <template v-if="$store.getters.isSystemAdmin">
                            {{ '[전체]' }}
                        </template>
                        <template v-else-if="objectUtil.notEmpty(loginUser?.sysClNm)">
                            {{ '[' + loginUser?.sysClNm + ']' }}
                        </template>
                        {{ loginUser.job?.map(jobInfo => jobInfo.jobTpNm).join(', ') }}
                    </li>
                </ul>
            </div>
        </div>
        <!--//headerIn-->
        <!--nav-bar-->
        <ComTopMenu :menuList="menuList"/>
        <!--//nav-bar-->
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { objectUtil } from '@/common/comUtil';
import comConstant from '@/common/comConstant';
import ServerTimer from '@/components/ServerTimer.vue';
import ComTopMenu from './ComTopMenu.vue';
import { logoutUserApi } from '@/api/userApi';

const store = useStore();

const loginUser = computed(() => store.getters.getLoginUser);
const isLogin = computed(() => store.getters.isLogin);

const menuList = computed(() => store.getters.getMenu);

const logout = (router) => {
    logoutUserApi()
        .finally(() => {
            store.commit('setLoginUser', null);
            router.push({ name: 'Main' });
        });
};

const moveMyPage = (router) => {
    if (loginUser.value.userTpCd == comConstant.userType.IN) {
        router.push({ name: 'MyUserInDetail' });

    } else if (loginUser.value.userTpCd == comConstant.userType.OUT) {
        router.push({ name: 'MyUserOutDetail' });
    }
};
</script>

<style scoped>
.userJobList {
    font-size: 12px;
    max-width: 350px;
    margin-left: 0 !important;
}
</style>