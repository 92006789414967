<!-- eslint-disable vue/multi-word-component-names -->
<!--
	(공통) 화면 좌측 메뉴
	
	@author pjg
	@since 2023.03.16
	@version 1.0
-->
<template>
	<div id="lnb">
		<h2>{{ currentMenu?.menuNm }}</h2>
		<ul class="lnb-navi" style="ov">
			<li v-for="subMenu in leftMenuList" :key="subMenu?.menuId">
				<router-link :to="{ path: (subMenu?.menuUrlAddr || '#') }" :class="{ on: (subMenu?.menuId == currentMenu?.menuId) }">
					{{ subMenu?.menuNm }}
				</router-link>
			</li>
		</ul>
	</div>
</template>

<script setup>
import { computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";


const store = useStore();
const router = useRouter();

const menuList = computed(() => store.getters.getMenu);

const currentMenu = computed(() => {
	let res = {};
	const currentMenuId = router.currentRoute?.value.meta.menu;
	
	if (currentMenuId) {
		res= menuList.value?.find(menu => menu.menuId == currentMenuId);
	}

	return res;
});

const leftMenuList = computed(() => {
	let res = [];

	if (menuList.value && currentMenu.value?.menuId) {
		res = menuList.value?.filter(menu => menu.uppoMenuId == currentMenu.value?.uppoMenuId);
	}

	return res;
});
</script>
