export default {
    /* 외부WEB: true, 내부WEB: false*/
    isAllowedFileUpload: document.location.protocol == 'https:' ? true : false,
    /**
     * 공통그룹코드
     */
    codeGroup: {
        SYS_CL_CD: '100', // 시스템구분코드
        JOB_CD: '110', // 전력채+기금운용 업무코드
        BOND_JOB_CD: '111', // 전력채 업무코드(전력채 차입종류코드 동일)
        FUND_JOB_CD: '112', // 기금운용 업무코드(기금운용 공고유형코드 동일)
        USER_TP_CD: '130', // 사용자유형코드
        ANNC_STS_CD: '140', // 공고상태코드
        BOND_ANNC_STS_CD: '141', // 전력채공고상태코드(재입찰X)
        FUND_ANNC_STS_CD: '142', // 기금운용공고상태코드
        INTRT_TP_CD: '150', // 금리유형코드
        TKOV_FIN_INSTT_CD: '160', // 인수금융기관코드
        PRINA_GIV_VCRS_INSTT_CD: '171', // 원리금지급대행기관코드
        INTR_CYCL_UNIT_CD: '180', // 이자주기단위코드
        INTR_TP_CD: '190', // 이자유형코드
        EXPR_PRD_UNIT_CD: '200', // 만기기간단위코드
        EXPR_PRD_UNIT_CD0: '201', // 만기기간단위코드
        //EXPR_PRD_UNIT_CD1: '202', // (은행차입)만기기간단위코드 그룹
        SRPAY_MHD_CD: '210', // 상황방법코드
        CRDT_GRD_CD01: '220', // 회사채 신용평가등급코드
        CRDT_GRD_CD02: '230', // 기업어음 신용평가등급코드
        EVL_INSTT_CD: '240', // 신용평가기관코드
        EVL_KND_CD: '250', // 신용평가종류코드
        FUND_BID_CD: '280', // 기금운용 입찰구분코드
        DEPST_PRD_CD: '290', // 기금운용 예치기간
        SVNG_GOODS_CD: '300', // 기금운용 예금상품
        SVNG_GOODS_CD1: '310', // 기금운용 확정금리형 예금상품
        SVNG_GOODS_CD2: '311', // 기금운용 정기예금 예금상품
        INTR_GIV_MHD_CD: '320', // 기금운용 이자지급방식
    },
    /**
     * 권한코드
     */
    auth: {
        BOND_MEMBER: '1',
        FUND_MEMBER: '2',
        BOND_ADMIN: '3',
        FUND_ADMIN1: '4',
        FUND_ADMIN2: '5',
        SYSTEM_ADMIN: '9'
    },
    /**
     * 시스템유형코드
     */
    systemType: {
        BOND: 'B',
        FUND: 'F'
    },
    /**
     * 신용평가기관코드
     */
    evlInsttCd: {
        KR: 'KR',
        NICE: 'NICE',
        KIS: 'KIS'
    },
    /**
     * 차입종류코드
     */
    brwKndCd: {
        BOND01: '1',
        BOND02: '2',
        BOND03: '3',
        BOND04: '4'
    },
    // /**
    //  * 차입종류별 평가종류코드
    //  */
    // divideEvlKndByBrwKnd: {
    //     '1': '220', // 1 전력채, 220 회사채 신용평가
    //     '2': '230', // 2 전단채, 230 기업어음 신용평가
    //     '3': '230', // 3 기업어음, 230 기업어음 신용평가
    //     '4': '230' // 4 은행차입, 230 기업어음 신용평가
    // },
    /**
     * 금리유형코드
     */
    intrtTpCd: {
        INTRTTP1: '1',
        INTRTTP2: '2',
        INTRTTP3: '3',
        INTRTTP4: '4'
    },
    boardType: {
        NOTICE_ID: '1', // 공지사항
        QNA_ID: '2', // 질의응답
    },
    bidStatus: {
        BIDING: '1', // 참여
        CANCEL: '2', // 취소
        SUCCESS: '3' // 낙찰
    },
    anncStatus: {
        SCHDULE: '1', // 예정
        PROGRESS: '2', // 진행
        JUDGE: '3', // 심사중
        SUCCESS: '4', // 낙찰
        FAIL: '5', // 유찰
        MODIFY: '6', // 정정
        CANCEL: '7', // 취소
        REBID: '8', // 재입찰 심사중
        REBID_PROGRESS: '9' // 재입찰진행중
    },
    /**
     * 유저타입
     */
    userType: {
        IN: 'I', // 내부사용자(실무자)
        OUT: 'O' // 외부사용자(회원사)
    },
    /**
     * 사용자상태코드
     */
    userStatus: {
        READY: '0', // 인증서 등록 완료
        APPROVE_WELFARE: '5', // 승인
        APPROVE_SCHOOL: '6', // 승인
        APPROVE: '1', // 승인
        REJECT: '2', // 반려
        WITHDRAWAL: '3', // 탈퇴
    },
    jobType: {
        BOND_POWER: '1', // 전력채
        BOND_SHORT: '2', // 전단채
        BOND_COMMERCIAL: '3', // 기업어음
        BOND_BANK: '4', // 은행차입
        FUND_WELFARE: '5', // 복지기금
        FUND_SCHOOL: '6' // 학교기금
    },
    tkovFinInsttType: {
        TKOVFININSTT_ETC: '2'  // 인수금융기관-기타
    },
    prinaGivVcrsInsttType: {
        PRINA_GIV_VCRS_INSTT_INPT_CTT: '21' // 원리금지급대행기관-직접입력
    },
    exprPrdUnitType: {
        EXPR_INPT_CTT: '4' // 만기기간단위-직접입력
    },
    authType: {
        MEMBER_BOND: '1', // 전력채 회원
        MEMBER_FUND: '2', // 기금운용 회원
        MNG_BOND_POWER: '3', // 발행채권 실무자
        MNG_FUND_WELFARE: '4', // 복지기금 실무자
        MNG_FUND_SCHOOL: '5', // 학교기금 실무자
        READY_UESR: '6',    // 미승인
        REJECT_USER: '7',    // 반려
        ADMIN: '9' // 관리자
    },
    jwt: {
        expired: '1003',
        newGeneration: '1004'
    },
    loginErrorCode: {
        USER_GONE_SELF_CERT: '1100',
        USER_LOGIN_PWD_EMPTY: '1101'
    },    
    /**
     * 금리유형
     */
    intrtType: {
        FIX: '1',
        FLOATING: '2',
        VARIABLE: '3',
        SPREAD: '4'
    },
    anncMailType: {
        REGIST: '1', // 등록
        UPDATE: '2', // 정정
        DELETE: '3', // 삭제
        CANCEL: '4', // 취소
        SUCCESS: '5', // 낙찰
        REBID: '6', // 재입찰
        REBID_TIME: '7' // 재입찰 시간변경
    },
    /**
     * 관리자구분코드
     */
    adminType: {
        POWER: 'P', // 전력채
        WELFARE: 'W', // 복지기금
        SCHOOL: 'S' // 학교기금
    },
    certEvent: {
        UNSELECTED_CERT: -999999999
    },
    pstTpCd: {
        SYSTEM: '9',
        POWER: '3', // 전력채
        WELFARE: '4', // 복지기금
        SCHOOL: '5' // 학교기금
    }
}