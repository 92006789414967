/**
 * 기금운용 입찰결과
 * 
 * @author bjy
 * @since 2023-04-25
 * @version 1.0
 */
export default [
	{
		path: '/bid/fund/result/bidProgressFund',
		name: 'BidProgressFund',
		component: () => import('@/views/bid/fund/result/BidProgressFund.vue'),
		meta: { contentTitle: '입찰진행현황', menu: 'AnncFundMng' },
		props: route => route.query
	},
	{
		path: '/bid/fund/result/bidSuccessFundMngDetail',
		name: 'BidSuccessFundMngDetail',
		component: () => import('@/views/bid/fund/result/BidSuccessFundMngDetail.vue'),
		meta: { contentTitle: '낙찰내역', menu: 'AnncFundMng' },
		props: route => route.query
	},
	// {
	// 	path: '/bid/fund/result/bidSuccessFundUsrList',
	// 	name: 'BidSuccessFundUsrDetail',
	// 	component: () => import('@/views/bid/fund/result/BidSuccessFundUsrDetail.vue'),
	// 	meta: { contentTitle: '낙찰내역', menu: 'AnncFundUsr' },
	// 	props: route => route.query
	// },
	{
		path: '/bid/fund/result/bidSuccessFundRegist',
		name: 'BidSuccessFundRegist',
		component: () => import('@/views/bid/fund/result/BidSuccessFundRegist.vue'),
		meta: { contentTitle: '최종입찰결과공고', menu: 'AnncFundMng' },
		props: route => route.query
	},
];