/**
 * 이용안내 라우터 설정
 *
 * @author jjw
 * @since 2023-05-23
 * @version 1.0
 */
export default [
	{
		path: '/sys/info/RegistProcView',
		name: 'RegistProc',
		component: () => import('@/views/sys/info/RegistProcView.vue'),
		meta: { contentTitle: '등록절차', menu: 'RegistProc' },
	},
	{
		path: '/sys/info/InfoUseView',
		name: 'InfoUse',
		component: () => import('@/views/sys/info/InfoUseView.vue'),
		meta: { contentTitle: '이용안내', menu: 'InfoUse' },
	},
	{
		path: '/sys/info/RegulationView',
		name: 'Regulation',
		component: () => import('@/views/sys/info/RegulationView.vue'),
		meta: { contentTitle: '규정 및 지침', menu: 'Regulation' },
	}
];
