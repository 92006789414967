/**
 * 파일 상태
 * @type {{C: string, N: string}}
 */
export const FileInfoStatus = {
    N: 'N',
    C: 'C'
}

/**
 * 파일 객체
 */
export default class FileInfo {

    constructor( fileName = '', dbData = {}, file = null, status = FileInfoStatus.N) {
        this.key = createUniqueKey();
        this.fileName = fileName;
        this.dbData = dbData;
        this.file = file;
        this.status = status;
    }

    get isData() {
        return this.dbData != null && Object.keys(this.dbData).length > 0;
    }

}

/**
 * 랜덤한 8자리 생성
 * @returns {string}
 * @private
 */
const createUniqueKey = () => {
    return 'xxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export const exportChangeFileList = (boardFileList) => {
    return boardFileList
        .filter(({status}) => status === FileInfoStatus.C)
        .map(({file}) => file);
}