import axiosInstance from "@/api/axiosInstance";

/**
 * 전력채 입찰공고 목록 조회
 * @param Object searchParam 
 * @returns 
 */
export const selectAnncBondUsrListApi = (searchParam) => {
	return axiosInstance.get('/annc/bond/usr/selectAnncBondUsrList.json', { params: searchParam });
};

/**
 * (입찰자 기준) 전력채 입찰공고 조회
 * @param Object searchParam 
 * @returns 
 */
export const selectAnncBondUsrListByBidrIdApi = (searchParam) => {
	return axiosInstance.get('/annc/bond/usr/selectAnncBondUsrListByBidrId.json', { params: searchParam });
};

/**
 * 전력채 입찰공고 조회
 * @param Object searchParam 
 * @returns 
 */
export const selectAnncBondUsrApi = (searchParam) => {
	return axiosInstance.get('/annc/bond/usr/selectAnncBondUsr.json', { params: searchParam});
};

/**
 * 전력채 입찰공고 조회
 * @param Object searchParam 
 * @returns 
 */
export const selectAnncBondUsrSummaryApi = (searchParam) => {
	return axiosInstance.get('/annc/bond/usr/selectAnncBondUsrSummary.json', { params: searchParam});
};

/**
 * 전력채 일정공고 목록 조회
 * @param Object searchParam 
 * @returns 
 */
export const selectAnncBondScheduleListApi = (searchParam) => {
	return axiosInstance.get('/annc/bond/usr/selectAnncBondScheduleList.json', { params: searchParam });
};