import axiosInstance from "@/api/axiosInstance";

/**
 * 게시물 유형 조회
 * @param {Object} searchParam
 * @returns
 */
export const selectBoardTypeApi = (searchParam) => {
	return axiosInstance.get('/boardType/selectBoardType.json', {params: searchParam});
};


/**
 * 게시물 목록 조회
 * @param {Object} searchParam
 * @returns
 */
export const selectBoardListApi = (searchParam) => {
	return axiosInstance.get('/board/selectBoardList.json', {params: searchParam});
};

/**
 * 긴급공지 게시물 목록 조회
 * @param {Object} searchParam
 * @returns
 */
export const selectBoardListByUrgnNtiApi = (searchParam) => {
	return axiosInstance.get('/board/selectBoardListByUrgnNti.json', {params: searchParam});
};

/**
 * 게시물 상세 조회
 * @param {Object} searchParam
 * @returns
 */
export const selectBoardApi = (searchParam) => {
	return axiosInstance.get('/board/selectBoard.json', {params: searchParam});
};

/**
 * 게시물 등록
 * @param {Object} registParam
 * @returns
 */
export const registBoardApi = (registParam) => {
	return axiosInstance.post('/board/registBoard.json', registParam, { headers: { 'Content-Type': 'multipart/form-data' }});
};

/**
 * 게시물 수정
 * @param {Object} updateParam
 * @returns
 */
export const updateBoardApi = (updateParam) => {
	return axiosInstance.post('/board/updateBoard.json', updateParam, { headers: { 'Content-Type': 'multipart/form-data' }});
};

/**
 * 게시물 삭제
 * @param {Object} deleteParam
 * @returns
 */
export const deleteBoardApi = (deleteParam) => {
	return axiosInstance.post('/board/deleteBoard.json', deleteParam);
};

/**
 * 게시물첨부파일 목록 조회
 * @param {Object} searchParam
 * @returns
 */
export const selectBoardFileListApi = (searchParam) => {
	return axiosInstance.get('/board/selectBoardFileList.json', {params: searchParam});
};

/**
 * 게시물첨부파일 삭제
 * @param {Object} deleteParam
 * @returns
 */
export const deleteBoardFileApi = (deleteParam) => {
	return axiosInstance.post('/board/deleteBoardFile.json', deleteParam);
};

/**
 * 게시물첨부파일 다운로드
 * @param {Object} downloadParam
 * @returns
 */
export const downloadBoardFileApi = (downloadParam) => {
	return axiosInstance.get('/board/downloadBoardFile.json', { params: downloadParam, responseType: 'blob' });
};

/**
 * 게시물 답변 목록 조회
 * @param {Object} searchParam
 * @returns
 */
export const selectBoardReplyListApi = (searchParam) => {
	return axiosInstance.get('/board/selectBoardReplyList.json', {params: searchParam});
};

/**
 * 게시물 답변 등록
 * @param {Object} registParam
 * @returns
 */
export const registBoardReplyApi = (registParam) => {
	return axiosInstance.post('/board/registBoardReply.json', registParam);
};

/**
 * 게시물 답변 삭제
 * @param {Object} deleteParam
 * @returns
 */
export const deleteBoardReplyApi = (deleteParam) => {
	return axiosInstance.post('/board/deleteBoardReply.json', deleteParam);
};
