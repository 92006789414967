import { createStore } from 'vuex';
import { createVuexPersistedState } from 'vue-persistedstate';
import { objectUtil } from '@/common/comUtil';
import comConstant from '@/common/comConstant';

// import dummyMenu from '@/dummy/dummyMenu';

export default createStore({
    plugins: [
      createVuexPersistedState({
        key: 'BFMS-STORE',
        storage: window.sessionStorage,
        whiteList: ['loginUser', 'searchParam', 'menu']
      })  
    ],
    state: () => ({
        loginUser: null,
        searchParam: null,
        menu: { list: null, iat: 0, loginUserId: null },
        loading: false,
        showTokenExpiredAlert: false,
        serverTime: { diffTime: 0 }
    }),
    getters: { // 변수들 getter
        getLoginUser: (state) => {
            return state.loginUser;
        },
        /**
         * 로그인상태인지 여부
         */
        isLogin: (state) => {
            return objectUtil.notEmpty(state.loginUser?.userId);
        },
        /**
         * 회원사인지 여부
         */
        isMember: (state) => {
            return state.loginUser?.userTpCd == comConstant.userType.OUT;
        },
        /**
         * 실무자인지 여부
         */
        isAdmin: (state) => {
            return state.loginUser?.userTpCd == comConstant.userType.IN;
        },
        /**
         * 전력채-발행채권실무자인지 여부
         */
        isBondAdmin: (state) => {
            return state.loginUser?.userTpCd == comConstant.userType.IN
                && state.loginUser?.auth?.includes(comConstant.auth.BOND_ADMIN);
        },
        /**
         * 전력기금실무자인지 여부
         */
        isFundAdmin1: (state) => {
            return state.loginUser?.userTpCd == comConstant.userType.IN
                && state.loginUser?.auth?.includes(comConstant.auth.FUND_ADMIN1);
        },
        /**
         * 학교기금실무자인지 여부
         */
        isFundAdmin2: (state) => {
            return state.loginUser?.userTpCd == comConstant.userType.IN 
                && state.loginUser?.auth?.includes(comConstant.auth.FUND_ADMIN2);
        },
        /**
         * 시스템관리자인지 여부
         */
        isSystemAdmin: (state) => {
            return state.loginUser?.userTpCd == comConstant.userType.IN
                && state.loginUser?.auth?.includes(comConstant.auth.SYSTEM_ADMIN);
        },
        /**
         * 메뉴 접근 권한 여부
         */
        hasMenuAccessPermission: (state) => (accessMenuId) => {
            const foundMenu = state.menu.list?.find(m => m.menuId == accessMenuId);
            const myInfoAccess = state.loginUser && (accessMenuId == 'MyUserIn' || accessMenuId == 'MyUserOut' || accessMenuId == 'MyCertMng'); // 나의정보 접근시 권한여부

            return myInfoAccess || objectUtil.isEmpty(accessMenuId) || objectUtil.notEmpty(foundMenu);
        },
        /**
         * (목록 화면에서 사용하는)검색조건 조회
         */
        getSearchParam: (state) => {
            return state.searchParam;
        },
        /**
         * @deprecated
         */
        // isCurrentViewSearchParam: (state) => (viewName) => {
        //     return state.searchParam?.viewName === viewName;
        // },
        /**
         * storage에서 메뉴 목록 조회
         */
        getMenu: (state) => {
            return state.menu.list;
        },
        /**
         * menu list 갱신 시간 조회
         */
        getMenuIat: (state) => {
            return state.menu ? state.menu.iat : 0;
        },
        /**
         * 메뉴 목록 조회에 사용한 사용자 ID
         */
        getMenuLoginUserId: (state) => {
            return state.menu ? state.menu.loginUserId : null;
        },
        /**
         * 서버시간 조회
         */
        getServerTime: (state) => {
            const dateObj = new Date(new Date().getTime() + state.serverTime.diffTime);
            const year = objectUtil.zeroFill(dateObj.getFullYear(), 2);
            const month = objectUtil.zeroFill(dateObj.getMonth() + 1, 2);
            const day = objectUtil.zeroFill(dateObj.getDate(), 2);
            const hour = objectUtil.zeroFill(dateObj.getHours(), 2);
            const minute = objectUtil.zeroFill(dateObj.getMinutes(), 2);
            const second = objectUtil.zeroFill(dateObj.getSeconds(), 2);

            return `${year}${month}${day}${hour}${minute}${second}`;
        },
        getSetShowTokenExpiredAlert: (state) => {
            return state.showTokenExpiredAlert;
        }
    },
    mutations: { // 변수들 조작하는 함수들
        setLoginUser: (state, loginUser) => {
            state.loginUser = loginUser;
        },
        setSearchParam: (state, searchParam) => {
            state.searchParam = searchParam;
        },
        setMenu: (state, [ menu, loginUserId ]) => {
            state.menu.list = menu;
            state.menu.iat = Math.floor(Date.now() / 1000);
            state.menu.loginUserId = loginUserId;
        },
        setLoading: (state, isLoading) => {
            state.loading = isLoading;
        },
        setShowTokenExpiredAlert: (state, showTokenExpiredAlert) => {
            state.showTokenExpiredAlert = showTokenExpiredAlert;
        },
        setServerTime: (state, serverTime) => {
            state.serverTime = serverTime;
        }
    }
});