/**
 * 샘플 라우터 설정
 * 
 * @author pjg
 * @since 2023-03-16
 * @version 1.0
 */
export default [
	{
		path: '/sample/SampleList',
		name: 'SampleList',
		component: () => import('@/views/sample/SampleList.vue'),
		meta: { contentTitle: '샘플 목록' }
	},
	{
		path: '/sample/SampleDetail',
		name: 'SampleDetail',
		component: () => import('@/views/sample/SampleDetail.vue'),
		meta: { contentTitle: '샘플 상세' },
		props: (route) => ({ id: route.query.id })
	},
	{
		path: '/sample/SampleRegist',
		name: 'SampleRegist',
		component: () => import('@/views/sample/SampleRegist.vue'),
		meta: { contentTitle: '샘플 등록' }
	},
	{
		path: '/sample/SampleUpdate',
		name: 'SampleUpdate',
		component: () => import('@/views/sample/SampleUpdate.vue'),
		meta: { contentTitle: '샘플 수정' },
		props: (route) => route.query
	}
];