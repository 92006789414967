import axiosInstanceNl from "@/api/axiosInstanceNl";

/**
 * (비로그인)사용자 권한 기준 메뉴 목록 조회
 */
export const selectMenuListByAnonymousUserApi = () => {
	return axiosInstanceNl.get('/menu/selectMenuListByAnonymousUser.json');
};

/**
 * (로그인)사용자 권한 기준 메뉴 목록 조회
 */
export const selectMenuListByLoginUserApi = () => {
	return axiosInstanceNl.get('/menu/selectMenuListByLoginUser.json');
};