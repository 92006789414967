
export default [
	{
		path: '/annc/schedule',
		name: 'AnncSchedule',
		component: () => import('@/views/annc/schedule/AnncSchedule.vue'),
		meta: { contentTitle: '공고일정', menu: 'AnncSchedule' },
		props: route => route.query
	},
];
