/**
 * 회원가입 라우터 설정
 * 
 * 
 * @author jsh
 * @since 2023-04-25
 * @version 1.0
 */
export default [
    {
        path: '/sys/user/userOutList',
        name: 'UserOutList',
        component: () =>import('@/views/user/sys/UserOutList.vue'),
        meta: { contentTitle: '회원사 목록', menu: 'OutMemberMng' },
        props: (route) => route.query
    },
    {
        path: '/sys/user/userOutDetail',
        name: 'UserOutDetail',
        component: () =>import('@/views/user/sys/UserOutDetail.vue'),
        meta: { contentTitle: '회원사 상세', menu: 'OutMemberMng' },
        props: (route) => route.query
    },
    {
        path: '/sys/user/userOutUpdate',
        name: 'UserOutUpdate',
        component: () =>import('@/views/user/sys/UserOutUpdate.vue'),
        meta: { contentTitle: '회원사 수정', menu: 'OutMemberMng' },
        props: (route) => route.query
    },
    {
        path: '/sys/user/userInList',
        name: 'UserInList',
        component: () =>import('@/views/user/sys/UserInList.vue'),
        meta: { contentTitle: '발행사 목록', menu: 'InMemberMng' },
        props: (route) => route.query
    },
    {
        path: '/user/sys/userInDetail',
        name: 'UserInDetail',
        component: () =>import('@/views/user/sys/UserInDetail.vue'),
        meta: { contentTitle: '발행사 상세', menu: 'InMemberMng' },
        props: (route) => route.query
    },
    {
        path: '/sys/user/userInUpdate',
        name: 'UserInUpdate',
        component: () =>import('@/views/user/sys/UserInUpdate.vue'),
        meta: { contentTitle: '발행사 수정', menu: 'InMemberMng' },
        props: (route) => route.query
    },
    {
        path: '/user/my/myUserOutDetail',
        name: 'MyUserOutDetail',
        component: () =>import('@/views/user/my/myUserOutDetail.vue'),
        meta: { contentTitle: '나의정보 상세', hiddenMenu: 'MyUserOut' },
        props: (route) => route.query
    },
    {
        path: '/user/my/myUserOutUpdate',
        name: 'MyUserOutUpdate',
        component: () =>import('@/views/user/my/myUserOutUpdate.vue'),
        meta: { contentTitle: '나의정보 수정', hiddenMenu: 'MyUserOut' },
        props: (route) => route.query
    },
    {
        path: '/user/my/myUserInDetail',
        name: 'MyUserInDetail',
        component: () =>import('@/views/user/my/myUserInDetail.vue'),
        meta: { contentTitle: '나의정보 상세', hiddenMenu: 'MyUserIn' },
        props: (route) => route.query
    },
    {
        path: '/user/my/myUserInUpdate',
        name: 'MyUserInUpdate',
        component: () =>import('@/views/user/my/myUserInUpdate.vue'),
        meta: { contentTitle: '나의정보 수정', hiddenMenu: 'MyUserIn' },
        props: (route) => route.query
    },
    {
        path: '/user/my/myCertMng',
        name: 'MyCertMng',
        component: () =>import('@/views/user/my/myCertMng.vue'),
        meta: { contentTitle: '인증서 관리', hiddenMenu: 'MyCertMng' },
        props: (route) => route.query
    },
    {
        path: '/user/my/passwordChange',
        name: 'PasswordChange',
        component: () =>import('@/views/user/my/PasswordChange.vue'),
        meta: { contentTitle: '비밀번호변경' , layout : 'default'}  
    },
    {
        path: '/user/my/mphoneChange',
        name: 'MphoneChange',
        component: () =>import('@/views/user/my/MphoneChange.vue'),
        meta: { contentTitle: '휴대폰번호변경' , layout : 'default'}  
    }
];