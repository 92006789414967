<!--
	(공통) 베이스 레이아웃
	router 설정에서 meta.layout 값에 의해 layout 연결
	
	@author pjg
	@since 2023.03.17
	@version 1.0
-->
<template>
	<component :is="layout($route)">
		<slot/>
	</component>
</template>

<script setup>
import { ref } from "vue";
import DefaultLayout from './DefaultLayout.vue';
import MainLayout from './MainLayout.vue';
import SubLayout from './SubLayout.vue';

const lookup = {
	DefaultLayout,
	MainLayout,
	SubLayout	
};

const layout = ref((route) => {
	if (route.meta.layout == 'main') {
		return lookup[ 'MainLayout' ];
	} else if (route.meta.layout == 'default') {
		return lookup[ 'DefaultLayout' ];
	} else {
		return lookup[ 'SubLayout' ];
	}
});
</script>