<!--
	(공통) 업무안내
	
	@author pjg
	@since 2023.03.16
	@version 1.0
-->
<template>
    <div class="float-box">
        <h2>업무안내</h2>
        <div class="cont">
            <p><span class="textJustify tc3">전력채</span> : 061-345-4225</p>
            <p><span class="textJustify tc3">전단채</span> : 061-345-4223</p>
            <p><span class="textJustify tc4">복지기금</span> : 061-345-4161</p>    
            <p><span class="textJustify tc4">학교기금</span> : 02-529-8731</p>     
            <p><span class="textJustify tc5">시스템문의</span> : 061-345-6547</p>   
            <p><span class="textJustify tc5">공인인증서</span> : 061-345-1249</p>    
        </div>
    </div>
</template>

<style scoped>
.tc3 { letter-spacing: 12px;}
.tc4 { letter-spacing: 4px;}
.tc5 { letter-spacing: 0;}
.textJustify { display: inline-block; width: 63px; }
</style>