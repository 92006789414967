/**
 * 전력채 입찰공고 라우터 설정
 * 
 * @author pjg
 * @since 2023-03-16
 * @version 1.0
 */
export default [
	{
		path: '/annc/bond/anncBondUsrList',
		name: 'AnncBondUsrList',
		component: () => import('@/views/annc/bond/usr/AnncBondUsrList.vue'),
		meta: { contentTitle: '입찰공고 목록', menu: 'AnncBondUsr' },
		props: route => route.query
	},
	{
		path: '/annc/bond/anncBondUsrDetail',
		name: 'AnncBondUsrDetail',
		component: () => import('@/views/annc/bond/usr/AnncBondUsrDetail.vue'),
		meta: { contentTitle: '입찰공고 상세', menu: 'AnncBondUsr' },
		props: route => route.query
	},
	{
		path: '/annc/bond/anncBondUsrSuccessDetail',
		name: 'AnncBondUsrSuccessDetail',
		component: () => import('@/views/annc/bond/usr/AnncBondUsrSuccessDetail.vue'),
		meta: { contentTitle: '낙찰결과', menu: 'AnncBondUsr' },
		props: route => route.query
	}
];