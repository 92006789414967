<!--
	메인 화면
	
	@author pjg
	@since 2023.03.16
	@version 1.0
-->
<template>
	<div class="notice-area layout-column display-f">
		<div class="main-title">
			<h2>공지사항</h2>
		</div>
		<MainNoticeSlider :noticeList="state.noticeList" ref="mainNoticeSliderRef"/>
	</div>
	<!--e:notice-area-->
	<div class="schedule layout-column display-f" v-if="!! loginUser && !objectUtil.isEmpty(state.anncTpCdList)">
		<div class="main-title" style="width:225px;">
			<h2>{{mainYm >>0}}월 입찰 일정</h2>
			<ul class="cal-tab-menu"> 
				<li v-for="(tab, idx) in state?.tabList" :key="idx"  >
					<a href="#" :class="{ on: (state.jobType === tab.tabCd) }" @click="() => moveTab(tab.tabCd)">{{ tab.tabNm }}</a>
				</li>
			</ul>
		</div>
		<div class="right">
			<MainSchedule ref="MainScheduleRef" :searchYm="scheduleParamYm" :searchedDates="scheduleParamDates" :systemType="state.searchParam.systemType" :anncTpCd="state.searchParam.anncTpCd"/>
		</div>
	</div>
	<div class="board-area layout-column layout-column-left">
		<div class="main-title">
			<h2>전력채 공고</h2>
			<a href="#" v-if="!loginUser" class="more-btn"></a>
			<router-link v-else :to="{ name: 'AnncBondUsrList', query: { tab: comConstant.jobType.BOND_POWER }}" class="more-btn"/>
		</div>
		<div class="board-wrap">
			<MainAnncList :dataList="state.anncListGroups[`B${comConstant.jobType.BOND_POWER}`]" :menuNm="'AnncBondUsr'"/>
		</div>
	</div>
	<!--e:board-area-전력채공고-->
	<div class="board-area layout-column layout-column-right">
		<div class="main-title">
			<h2>전단채 공고</h2>
			<a href="#" v-if="!loginUser" class="more-btn"></a>
			<router-link v-else :to="{ name: 'AnncBondUsrList', query: { tab: comConstant.jobType.BOND_SHORT } }" class="more-btn"/>
		</div>
		<div class="board-wrap">
			<MainAnncList :dataList="state.anncListGroups[`B${comConstant.jobType.BOND_SHORT}`]" :menuNm="'AnncBondUsr'"/>
		</div>
	</div>
	<!--e:board-area-전단채공고-->
	<div class="board-area layout-column layout-column-left">
		<div class="main-title">
			<h2>기업어음 공고</h2>
			<a href="#" v-if="!loginUser" class="more-btn"></a>
			<router-link v-else :to="{ name: 'AnncBondUsrList', query: { tab: comConstant.jobType.BOND_COMMERCIAL } }" class="more-btn"/>
		</div>
		<div class="board-wrap">
			<MainAnncList :dataList="state.anncListGroups[`B${comConstant.jobType.BOND_COMMERCIAL}`]" :menuNm="'AnncBondUsr'"/>
		</div>
	</div>
	<!--e:board-area-기업어음 공고-->
	<div class="board-area layout-column layout-column-right">
		<div class="main-title">
			<h2>은행차입 공고</h2>
			<a href="#" v-if="!loginUser" class="more-btn"></a>
			<router-link v-else :to="{ name: 'AnncBondUsrList', query: { tab: comConstant.jobType.BOND_BANK } }" class="more-btn"/>
		</div>
		<div class="board-wrap">
			<MainAnncList :dataList="state.anncListGroups[`B${comConstant.jobType.BOND_BANK}`]" :menuNm="'AnncBondUsr'"/>
		</div>
	</div>
	<!--e:board-area-은행채 공고-->
	<div class="board-area layout-column layout-column-left m-b-0">
		<div class="main-title">
			<h2>복지기금 공고</h2>
			<a href="#" v-if="!loginUser" class="more-btn"></a>
			<router-link v-else :to="{ name: 'AnncFundUsrList', query: { tab: comConstant.jobType.FUND_WELFARE } }" class="more-btn"/>
		</div>
		<div class="board-wrap">
			<MainAnncList :dataList="state.anncListGroups[`F${comConstant.jobType.FUND_WELFARE}`]" :menuNm="'AnncFundUsr'"/>
		</div>
	</div>
	<!--e:board-area-전력기금 공고-->
	<div class="board-area layout-column layout-column-right m-b-0">
		<div class="main-title">
			<h2>학교기금 공고</h2>
			<a href="#" v-if="!loginUser" class="more-btn"></a>
			<router-link v-else :to="{ name: 'AnncFundUsrList', query: { tab: comConstant.jobType.FUND_SCHOOL } }" class="more-btn"/>
		</div>
		<div class="board-wrap">
			<MainAnncList :dataList="state.anncListGroups[`F${comConstant.jobType.FUND_SCHOOL}`]" :menuNm="'AnncFundUsr'"/>
		</div>
	</div>
	<!--e:board-area-학교기금 공고-->
	<AlertModal ref="alertMd"/>
</template>

<script setup>
import { computed, onMounted, reactive, ref, watch } from "vue";
import { useStore } from 'vuex';
import { objectUtil } from '@/common/comUtil';
import comConstant from '@/common/comConstant';
import mainManager from "./modules/mainManager";
import MainAnncList from '@/components/main/MainAnncList';
import MainSchedule from '@/components/main/MainSchedule';
import MainNoticeSlider from '@/components/main/MainNoticeSlider';
import AlertModal from '@/components/AlertModal';

const store = useStore();
const alertMd = ref(null);
const mainNoticeSliderRef = ref(null);
const MainScheduleRef = ref(null);

const DEFAULT_TAB = [
  { tabNm: '전력채', tabCd: comConstant.systemType.BOND },
  { tabNm: '복지기금', tabCd: comConstant.jobType.FUND_WELFARE },
  { tabNm: '학교기금', tabCd: comConstant.jobType.FUND_SCHOOL }
];

// eslint-disable-next-line no-unused-vars
const state = reactive({
	anncListGroups: { /* 차입종류별, 공고유형별 공고목록 저장 / (key)B?, F? 형식 */ },
	brwKndCdList: [],
	anncTpCdList: [],
	noticeList: [],
    searchParam: {
        pageNo: 1,
        pageSize: 3,
        anncStsCd: '',
        brwKndCds: null,
        anncTpCds: null,
		targetDate: new Date(),
        systemType: '',
        anncTpCd: '',
    },
	codeGroups: {
		brwKndList: []
	},
	tabList: Array.from(DEFAULT_TAB),
	loginUserJob: '',
});

const loginUser = computed(() => {
	return store.getters.getLoginUser;
});

const scheduleParamYm = computed(() => {
	const dateObj = new Date();
	return `${dateObj.getFullYear()}${objectUtil.zeroFill(dateObj.getMonth() + 1, 2)}`;
});

const scheduleParamDates = computed(() => {
	return mainManager.getCalendarDates(scheduleParamYm.value);
});

const mainYm = computed(() => {
	return MainScheduleRef.value?.reactiveValue.mainYm.substring(4,6);
}); 

/**
 * 조회 가능한 공고유형 setter
 */
const setAnncTpCds = () => {
    const loginUser = store.getters.getLoginUser;
	state.anncTpCdList = loginUser?.job?.map(jobInfo => jobInfo.jobTpCd);
};

/**
 * 공고일정 tab
 */
const moveTab = (jobType) => {
	if(jobType == comConstant.jobType.FUND_SCHOOL || jobType == comConstant.jobType.FUND_WELFARE){
		state.searchParam.anncTpCd = jobType;
		state.searchParam.systemType = comConstant.systemType.FUND;
	} else {
    	state.searchParam.systemType = jobType;
	} 
  	state.jobType = jobType;
	MainScheduleRef.value?.searchAnncData(true, state.searchParam);
};

/**
 * 공고 목록 조회
 * '전력채 차입종류', '기금운용 공고유형'이 같은 개념인데 통합 못하여 각각 처리함
 * 추후 통합 필요
 */
const search = () => {
	// 공고 목록 처리
	// 전력채, 기금운용별 업무에 따라 각각 목록 조회
	// 이미 조회된 내용도 갱신
	const brwKndCdList = Object.values(comConstant.brwKndCd);

	if (objectUtil.isEmpty(state.anncTpCdList)) {
		// 공고목록 빈값으로 초기화
		Object.values(state.anncListGroups).forEach(anncList => anncList.length = 0);
		return false;
	}

	// 공고목록 갱신
	state.anncTpCdList?.forEach(anncTpCd => {
		if (brwKndCdList.includes(anncTpCd)) {
			state.searchParam.brwKndCd = anncTpCd;
			state.searchParam.anncTpCd = null;
			mainManager.selectAnncBondList(state, `B${anncTpCd}`);

		} else {
			state.searchParam.anncTpCd = anncTpCd;
			state.searchParam.brwKndCd = null;
			mainManager.selectAnncFundList(state, `F${anncTpCd}`);
		}
	});
};

/**
 * 권한 따른 일정 탭 설정
 */
const settingTabList = () => {
    const authSet = new Set();

	if (objectUtil.isEmpty(state.anncTpCdList) || !store.getters.getLoginUser) {
		return false;
	}
	//사용자, 실무자
	if(state.anncTpCdList?.includes(comConstant.jobType.FUND_WELFARE)) {
		authSet.add(comConstant.jobType.FUND_WELFARE);
		state.searchParam.systemType = comConstant.systemType.FUND;
		state.searchParam.anncTpCd = comConstant.jobType.FUND_WELFARE;
		state.jobType = comConstant.jobType.FUND_WELFARE;
	} 
	if (state.anncTpCdList?.includes(comConstant.jobType.FUND_SCHOOL)) {
		state.searchParam.systemType = comConstant.systemType.FUND;
		authSet.add(comConstant.jobType.FUND_SCHOOL);
		state.searchParam.anncTpCd = comConstant.jobType.FUND_SCHOOL;
		state.jobType = comConstant.jobType.FUND_SCHOOL;
	}
	if(state.anncTpCdList?.includes(comConstant.jobType.FUND_WELFARE)
			&& state.anncTpCdList?.includes(comConstant.jobType.FUND_SCHOOL)){
		state.jobType = comConstant.jobType.FUND_WELFARE;
	}
	if(state.anncTpCdList?.includes(comConstant.jobType.BOND_POWER)
				||state.anncTpCdList?.includes(comConstant.jobType.BOND_SHORT)
				||state.anncTpCdList?.includes(comConstant.jobType.BOND_COMMERCIAL)
				||state.anncTpCdList?.includes(comConstant.jobType.BOND_BANK)) {
		authSet.add(comConstant.systemType.BOND);
		state.jobType = comConstant.systemType.BOND;
		state.searchParam.systemType = comConstant.systemType.BOND;
    }
	// 관리자
    if(store.getters.isSystemAdmin) {
      authSet.add(comConstant.systemType.BOND);
      authSet.add(comConstant.jobType.FUND_WELFARE);
      authSet.add(comConstant.jobType.FUND_SCHOOL);
      state.jobType = comConstant.systemType.BOND;
      state.searchParam.systemType = comConstant.systemType.BOND;
    }

    state.tabList = DEFAULT_TAB.filter(tab => authSet.has(tab.tabCd));
	if(state.tabList?.length > 0) {
    	if(state.tabList[0].tabCd != comConstant.systemType.BOND) {
        	state.searchParam.anncTpCd = state.tabList[0].tabCd;
    	} 
    } 
	MainScheduleRef.value?.searchAnncData(true, state.searchParam);
}

onMounted(async() => {
	setAnncTpCds();
	search();
	// 긴급공지 목록 조회 (긴급공지는 권한체크 필요 없어서 watch에서는 호출 안함)
	await mainManager.selselectBoardListByUrgnNti(state);
	mainNoticeSliderRef.value?.start();
	settingTabList();
});

watch(loginUser, () => {
	setAnncTpCds();
	search();
	settingTabList();
});

</script>
