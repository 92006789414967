/**
 * 메뉴 관련 스크립트
 * 
 * @author pjg
 * @since 2023.05.12
 * @version 1.0
 */
import { selectMenuListByLoginUserApi, 
         selectMenuListByAnonymousUserApi } from '@/api/menuApi';
import { cryptoUtil } from '@/common/comUtil';

export default {
    /**
     * 메뉴 목록 초기화
     * jwt가 갱신된 시간과 메뉴 목록 갱신된 시간을 비교해서
     * jwt가 이후에 갱신되었다면 메뉴 목록을 갱신한다.
     * jwt가 null일 경우도 갱신함
     */
    initMenuList: async(store) => {
        const loginUser = store.getters.getLoginUser;
        const menuIat = store.getters.getMenuIat;
        const menuLoginUserId = store.getters.getMenuLoginUserId;
        
        const token = loginUser?.jwtToken;
        const loginUserId = loginUser?.userId || null;
        const payload = token ? JSON.parse(cryptoUtil.base64Decode(token.split('.')[1])) : { iat: 0 };

        if (menuLoginUserId == loginUserId && menuIat > 0 && menuIat > payload.iat) {
            return false;
        }

        const menuListByAuthApi = loginUserId ? selectMenuListByLoginUserApi() : selectMenuListByAnonymousUserApi();

        try {
            await menuListByAuthApi.then((res) => { store.commit('setMenu', [res.data.resultList, loginUserId]); });

        } catch(e) {
            console.log(e);
        }
    }
}