import FileInfo, {FileInfoStatus} from "@/components/file/FileInfo";
import comConstant from '@/common/comConstant';
import { Buffer } from 'buffer';

/**
 * 날짜시간을 자바스크립트 Date 객체로 변환
 */
const strToDateObj = (dateTimeString) => {
    const year = dateTimeString && dateTimeString.length >= 4 ? dateTimeString.substring(0, 4) : '0';
    const month = dateTimeString && dateTimeString.length >= 6 ? dateTimeString.substring(4, 6) - 1 : '0';
    const day = dateTimeString && dateTimeString.length >= 8 ? dateTimeString.substring(6, 8) : '0';
    const hour = dateTimeString && dateTimeString.length >= 10 ? dateTimeString.substring(8, 10) : '0';
    const minute = dateTimeString && dateTimeString.length >= 12 ? dateTimeString.substring(10, 12) : '0';
    const second = dateTimeString && dateTimeString.length >= 14 ? dateTimeString.substring(12, 14) : '0';

    return new Date(year, month, day, hour, minute, second);
};


/**
 * 데이터 조작 빈값 체크 등 함수 모음
 */
export const objectUtil = {
    isEmpty: (value) => {
        if (typeof value == 'undefined' || value == null || value == '') {
            return true;
        } else if (Array.isArray(value) && value.length == 0) {
            return true;
        } else {
            return false;
        }
    }, 
    /**
     * undefined, null, 빈값 체크
     * 배열 체크 추가
     * @param Object(String) value 
     */
    notEmpty: (value) => {
        if (typeof value == 'undefined' || value == null || value == '') {
            return false;
        } else if (Array.isArray(value) && value.length == 0) {
            return false;
        } else {
            return true;
        }
    },
    /**
     * value 뒤에 추가 문자를 붙여서 리턴
     * ex) objectUtil.postFix(value, '%')
     * @param String value 
     * @param String fixWord 
     * @returns 
     */
    postFix: (value, fixWord) => {
        return objectUtil.notEmpty(value) 
            ? value + ' ' + (objectUtil.notEmpty(fixWord) ? fixWord : '')
            : value;
    },
    /**
     * 자리수만큼 앞에 0채움
     * @param Number value 
     * @returns 
     */
    zeroFill: (value, fillCount) => {
        const no = value>>0;
        return no.toString().padStart(fillCount, '0');
    },
    /**
     * source 복제
     * @param { Object } source 
     * @returns 
     */
    copy: (source) => {
        return JSON.parse(JSON.stringify(source));
    },
    /**
     * Object 목록에서 key에 해당하는 값이 value와 같은값이 있는지 체크
     * @param {*} list 
     * @param {*} value 
     * @param {*} key 
     * @returns 
     */
    includesByKey: (list, value, key) => {
        return list.some(item => item[key] == value);
    },
    /**
     * 한글 마지막 글자 받침 여부 체크 (~을/를 등 구분용, 영문은 안됨)
     * true 받침있음, false 받침없음 
     * @param String korStr 
     */
    isEndWithConsonant(korStr) {
        const finalCharCode = korStr.charCodeAt(korStr.length - 1);
        const finalConsonantCode = (finalCharCode - 44032) % 28;
        return  finalConsonantCode !== 0;
    },
    /**
     * list의 key로 묶음
     * @param {*} list 
     * @param {*} key 
     * @returns 
     */
    groupBy : (list, key) => {
        if(!list) return {};
        return list.reduce((accObj, currentObj) => {
            const type = currentObj[key];
            if(accObj[type]) {
                accObj[type].push(currentObj);
            } else {
                accObj[type] = [currentObj];
            }
            return accObj;
        }, {})
    },
    /**
     * 입력값이 특정 문자(chars)만으로 되어있는지 체크
     * @param String value
     * @returns 
     */
    containsCharsOnly: (value, chars) => {
        for (var i = 0; i < value.length;  i++) {
            if (chars.indexOf(value.charAt(i)) == -1) {
                return false;
            }
        }
        return true;
    },
    /**
     * 입력값이 특정 문자(chars)로 되어있는지 체크
     * @param String value
     * @returns 
     */
    containsChars: (value, chars) => {
        for (var i = 0; i < value.length;  i++) {
            if (chars.indexOf(value.charAt(i)) != -1) {
                return true;
            }
        }
        return false;
    },
    /**
     * 삭제예정 사용하지 않음
     * 입력값이 영문자, 숫자로 되어있는지 되어있는지 체크
     * @param String value 
     * @returns 
     */
    checkEngNum: (value) => {
        return objectUtil.containsCharsOnly(value, '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz');
    },
    /**
     * 빈 값으로 교체
     * @param String value
     * @returns 
     */
    clearValue: (value) => {
        let values = [];
        if (objectUtil.isEmpty(value)) {
            value = '';
        }
        if (!Array.isArray(value)) {
            values = [value];
        } else {
            values = value;
        }
        for (value of values) {
            value = '';
        }
    },
    /**
     * 한글자 이외 *로 표시
     * @param String value
     * @returns 
     */
    substrValue: (value) => {
        if (! objectUtil.isEmpty(value)) {
            return value.substring(0,1) + "*";
        }
    }
};

/**
 * 날짜 관련 함수 모음
 */
export const dateUtil = {
    /**
     * 오늘날짜를 yyyy-mm-dd 포맷으로 리턴
     * 파라미터에 따라 날짜 변경 가능
     * @param Number addDay (+-일)
     * @param Number addMonth (+-월)
     * @param Number addYear (+-년)
     * @returns 
     */
    todayYmd: (addDay, addMonth, addYear) => {
        const now = new Date();
        now.setFullYear(now.getFullYear() + (addYear>>0));
        now.setMonth(now.getMonth() + (addMonth>>0));
        now.setDate(now.getDate() + (addDay>>0));
        const yyyy = now.getFullYear();
        const mm = (now.getMonth() + 1).toString().padStart(2, '0');
        const dd = (now.getDate()).toString().padStart(2, '0');
        return `${yyyy}${mm}${dd}`;
    },
    /**
     * 현재시간 반환
     * 또는 value(yyyyMMddHHmmss)에 해당하는 시간 반환
     * @returns yyyy-MM-dd HH:mm:ss
     */
    now: (value, forMatType) => {
        const time = value + '';
        
        if (objectUtil.notEmpty(time) && time.length === 14) {
            return `${time.substring(0, 4)}년 ${time.substring(4, 6)}월 ${time.substring(6, 8)}일 ${time.substring(8, 10)}:${time.substring(10, 12)}:${time.substring(12, 14)}`;
        }

        const now = new Date();
        const yyyy = now.getFullYear();
        const mm = (now.getMonth() + 1).toString().padStart(2, '0');
        const dd = (now.getDate()).toString().padStart(2, '0');
        const hh = now.getHours().toString().padStart(2, '0');
        const mi = now.getMinutes().toString().padStart(2, '0');
        const ss = now.getSeconds().toString().padStart(2, '0');

        if (forMatType == 1)
            return `${yyyy}${mm}${dd}${hh}${mi}${ss}`;
        else {
            return `${yyyy}년 ${mm}월 ${dd}일 ${hh}:${mi}:${ss}`;
        }
    },
    /**
     * HHMI -> format type에 따라 변환 (HH:MI 등)
     */
    formattedHm: (value, type = '1') => {
        const time = value + '';

        if (objectUtil.notEmpty(value) && time.length === 4) {
            switch(type) {
                case '1': // HH:MI
                    return `${time.substring(0, 2)}:${time.substring(2, 4)}`;
                case '2': // HH시 Mi분
                    return `${time.substring(0, 2)}시 ${time.substring(2, 4)}분`;
            }
            
        }

        return value;
    },
    /**
     * YYMMDD -> format type에 따라 변환 (YYYY-MM-DD 등)
     * @param String(Number) value 
     * @returns 
     */
    formattedYmd: (value, type) => {
        if (objectUtil.isEmpty(type)) {
            type = '1';
        }
        if (objectUtil.notEmpty(value) && value.length >= 8) {
            const dateObj = strToDateObj(value);
            const dayOfWeeks = ['일', '월', '화', '수', '목', '금', '토'];

            switch(type) {
                case '1': // YYYY-MM-DD
                    return `${value.substring(0, 4)}-${value.substring(4, 6)}-${value.substring(6, 8)}`;
                case '2': // YYYY .m .d
                    return `${dateObj.getFullYear()} .${dateObj.getMonth() + 1} .${dateObj.getDate()}`;
                case '3': // YYYY .m .d (요일)
                    return `${dateObj.getFullYear()} .${dateObj.getMonth() + 1} .${dateObj.getDate()} (${dayOfWeeks[dateObj.getDay()]})`;
                case '4': // YYYY년 m월 d일
                return `${dateObj.getFullYear()}년 ${dateObj.getMonth() + 1}월 ${dateObj.getDate()}일`;                    
            }
        }
        return value;
    },
    /**
     * LocalDateTime remove T
     * @param String(Number) value 
     * @returns 
     */
    formattedDateTime: (value, type) => {
        if (objectUtil.isEmpty(type)) {
            type = '1';
        }
        if (objectUtil.notEmpty(value)) {
            switch(type) {
                case '1':
                    return value.replace('T', ' ');
                case '2': {
                    const date = new Date(value);
                    const yyyy = date.getFullYear();
                    const mm = (date.getMonth() + 1).toString().padStart(2, '0');
                    const dd = (date.getDate()).toString().padStart(2, '0');
                    return `${yyyy}-${mm}-${dd}`;
                }
                case '3': {
                    const date = new Date(value);
                    const yyyy = date.getFullYear();
                    const mm = (date.getMonth() + 1).toString().padStart(2, '0');
                    const dd = (date.getDate()).toString().padStart(2, '0');
                    return `${yyyy}${mm}${dd}`;
                }
                case '4': {
                    const date = new Date(value);
                    const yyyy = date.getFullYear();
                    const mm = (date.getMonth() + 1).toString().padStart(2, '0');
                    const dd = (date.getDate()).toString().padStart(2, '0');
                    const hh = date.getHours();
                    const mn = date.getMinutes();
                    const ss = date.getSeconds();
                    return `${yyyy}${mm}${dd}${hh}${mn}${ss}`;
                }
            }
        }
        return value;
    },
    /**
     * 사업자 번호 123-12-12345 표시
     * @param String value 사업자번호
     * @returns 
     */
    formattedBizrNo: (value) => {
        return value.substring(0, 3) + '-' + value.substring(3, 5)+ '-' + value.substring(5, 10);
    },
    /**
     * 법인 번호 123456-1234567 표시
     * @param String value 법인번호
     * @returns 
     */
    formattedCorpNo: (value) => {
        return value.substring(0, 6) + '-' + value.substring(6, 13);
    },
    strToDateObj: strToDateObj,
    /**
     * 시간 비교 date1 이 크면 1, 작으면 -1 같으면 0
     * @param {*} date1 
     * @param {*} date2 
     * @returns 
     */
    compareToDateTime: (date1, date2) => {
        const diff = date1.getTime() - date2.getTime();
        return diff > 0 ? 1 : (diff < 0 ? -1 : 0);
    },
    /**
     * 해당 date의 1월 1일
     */
    firstDayOfYear: (date) => {
        return new Date(date.getFullYear(), 0, 1);
    },
    /**
     * 해당 date의 12월 31일
     */
    lastDayOfYear: (date) => {
        return new Date(date.getFullYear(), 11, 31);
    },
    /**
     * 만기기간 설정
     */
    getNewExprYmd: (exprPrd, exprPrdUnitCd, pblsSchlYmd) => {
        if (objectUtil.isEmpty(pblsSchlYmd)) {
            return;
        }

        let newExprYmd = new Date(pblsSchlYmd);

        if(exprPrdUnitCd == '1') { //년
            newExprYmd = newExprYmd.setFullYear(newExprYmd.getFullYear() + exprPrd);
        } else if(exprPrdUnitCd == '2') { //개월
            newExprYmd = newExprYmd.setMonth(newExprYmd.getMonth() + exprPrd);
        } else if(exprPrdUnitCd == '3') { //일
            newExprYmd = newExprYmd.setDate(newExprYmd.getDate() + exprPrd);
        }
        newExprYmd = dateUtil.formattedDateTime(newExprYmd, '3');
        return newExprYmd;
    },
};

/**
 * 숫자관련 함수들
 */
export const numberUtil = {
    /**
     * 금액에 3자리수마다 콤마 추가
     * @param Number value 
     * @returns 
     */
    comma: (value) => {
        if (objectUtil.isEmpty(value)) {
            return '0';
        } else if ((value/1).toString() == 'NaN') {
            return value;
        } else {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
    },
    /**
     * 숫자인지 체크
     * @param {Number} value 
     * @returns 
     */
    isNumber: (value) => {
        return (value/1).toString() !== 'NaN';
    },
    /**
     * 숫자 배율 체크
     * ex) 값이 100의 배수인지 numberUtil.stepNumber(300, 100) => true
     *                        numberUtil.stepNumber(301, 100) => false
     * @param {Number} value 
     * @param {Number} unit 
     * @returns 
     */
    stepNumber: (value, unit) => {
        return (value>>0) % unit === 0;
    },
    /**
     * 한글 형식으로 숫자 변환
     * @returns 
     */
    convertNumberToKrUnit: (number) => {
        const koreanNumber = ['', '일', '이', '삼', '사', '오', '육', '칠', '팔', '구'];
        const tenUnit = ['', '십', '백', '천'];
        const tenThousandUnit = ['조', '억', '만', ''];
        const unit = 10000;

        let result = '';

        while(number > 0) {
            const mod = number % unit;
            const modToArray = mod.toString().split('');
            const length = modToArray.length - 1;

            const modToKorean = modToArray.reduce((acc, value, index) => {
                const valueToNumber = +value;
                if (!valueToNumber) {
                    return acc;
                }

                const numberToKorean = koreanNumber[valueToNumber];
                return `${acc}${numberToKorean}${tenUnit[length - index]}`;
            }, '');

            if(modToKorean === ""){
                tenThousandUnit.pop();
                result = `${modToKorean}${result}`;
            }else{
                result = `${modToKorean}${tenThousandUnit.pop()}${result}`;
            }
            number = Math.floor(number / unit);
        }

        return result.replace();
	} 
};

/**
 * 데이터 검증용 함수들
 */
export const validateUtil = {
    /**
     * 이메일 체크
     * @param {*} value 
     * @returns 
     */
    emailTest: (value) => {
        // eslint-disable-next-line no-useless-escape
        return /^([\w\.\_\-])*[a-zA-Z0-9]+([\w\.\_\-])*([a-zA-Z0-9])+([\w\.\_\-])+@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,8}$/.test(value);
    },
    /**
     * 123456.01 값이
     * ######.## 형식의 mask에 맞는지 자리수 체크
     * 0.## 형식은 정수부 체크 안함
     */
    decimalTest: (value, mask) => {
        if (objectUtil.isEmpty(mask)) {
            return false;
        }

        const valueParts = (value + '').split('.');
        const maskParts = (mask + '').split('.');
        
        if (maskParts[0] != '0' && valueParts[0].length > maskParts[0].length) {
            return false;
        }

        const primeNumber = valueParts.length > 1 ? valueParts[1] : '';
        const primeMask = maskParts.length > 1 ? maskParts[1] : '';

        if (primeNumber.length > primeMask.length) {
            return false;
        }

        return true;
    }
}

export const formUtil = {
    /**
     * 첨부파일 + 등록(or 수정) 데이터 formData로 반환
     * parmsName 없으면 formData에 key, value로 추가되고
     *           있으면 formData에 key는 parmsName, value는 params를 String으로 변환해서 적용
     * @param {Object} params 
     * @param {Array} fileList 
     * @param {String} fileListName 
     * @returns 
     */
    toFormData: ([params, parmsName], [fileList, fileListName]) => {
        const formData = new FormData();

        if (objectUtil.notEmpty(parmsName)) {
            formData.append(parmsName || 'params', JSON.stringify(params));
        } else {
            Object.keys(params).forEach(key => {
                if (params[key]) formData.append(key, params[key]);
            });
        }

        if (objectUtil.notEmpty(fileList)) {
            //fileList.forEach((file, i) => formData.append(`${fileListName || 'fileList'}[${i}]`, file));
            fileList.forEach((file) => {
                if(file instanceof FileInfo) {
                    if(file.status === FileInfoStatus.C ) {
                        formData.append(`${fileListName || 'fileList'}`, file.file)
                    }
                } else {
                    formData.append(`${fileListName || 'fileList'}`, file)
                }
                
            });
        }
        
        return formData;
    },

    /**
     * 영문자로 되어있는지 체크
     * @param {*} value 
     * @returns 
     */
    isEngilishCheck : (value) => {
        return value.toString(/ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz/);
    },
    /**
     * 영문자,숫자로 되어있는지 체크
     */
    isEngNumCheck : (value) => {
        return value.tostring(/0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz/);
    }

}

export const pagingUtil = {
    getRowNo: ({totalCount, pageNo, pageSize}, rowNumber) => {
        return totalCount - ((pageNo - 1) * pageSize + rowNumber);
    }
}

export const cryptoUtil = {
    base64Decode: (base54EncodedText) => {
        return Buffer.from(base54EncodedText, 'base64').toString('utf8');
    }
}

export const fileUtil = {
    /**
     * 파일 업로드 가능 여부 확인(내부망에서는 파일 업로드 불가)
     * @returns 
     */
    checkAllowedFileUpload: () => {
        return comConstant.isAllowedFileUpload;
    },
    /**
     * 파일 업로드 확장자 체크
     * @returns 
     */
    checkUploadFileExtension: (fileExtension) => {
        const allowExtensionArr = ['gif', 'png', 'jpg', 'jpeg', 'doc', 'docx', 'xls', 'xlsx', 'hwp', 'pdf', 'pptx', 'zip'];
        return allowExtensionArr.find(allowExtension => allowExtension.toLowerCase() == fileExtension);
    }

}

export const printUtil = {
    /**
     * 사업자 번호 123-12-12345 표시
     * @param String value 사업자번호
     * @returns 
     */
    printBizrNo: (value, type) => {
        if (objectUtil.isEmpty(value)) {
            return '';
        }
        if (objectUtil.isEmpty(value)) {
            type = '0';
        }
        if (type == '1') {
            return value.substring(0, 3) + '-' + value.substring(3, 5) + '-' + value.substring(5, 10);    
        }
        return value.substring(0, 3) + '-' + value.substring(3, 5)+ '-' + '*****';
    },
    /**
     * 법인 번호 123456-1234567 표시
     * @param String value 법인번호
     * @returns 
     */
    printCorpNo: (value) => {
        if (objectUtil.isEmpty(value)) {
            return '';
        }
        return value.substring(0, 6) + '-' + '*******';
    },
    /**
     * 생년월일 표시
     * @param String value 생년월일
     * @returns 
     */
    printBirthYmd: (value) => {
        if (objectUtil.isEmpty(value)) {
            return '';
        }
        return value.substring(0, 4) + '-' + value.substring(4, 6)+ '-' + value.substring(6, 8);
    },
    /**
     * 업무명 표시
     * @param String code 코드
     * @returns 
     */
    printJobName: (code) => {
        if (code == comConstant.jobType.BOND_POWER) {
            return '전력채';
        } else if (code == comConstant.jobType.BOND_SHORT) {
            return '전단채';
        } else if (code == comConstant.jobType.BOND_COMMERCIAL) {
            return '기업어음';
        } else if (code == comConstant.jobType.BOND_BANK) {
            return '은행예금';
        } else if (code == comConstant.jobType.FUND_WELFARE) {
            return '복지기금';
        } else if (code == comConstant.jobType.FUND_SCHOOL) {
            return '학교기금';
        }
        return '';
    },
    /**
     * 업무명 표시
     * @param String code 코드
     * @returns 
     */
    printJobNameArr: (jobTypeArr) => {
        if (objectUtil.isEmpty(jobTypeArr)) {
            return '';
        }

        const jobNameArr = [];
        for (const jobType of jobTypeArr) {
            if (jobType == comConstant.jobType.BOND_POWER) {
                jobNameArr.push('전력채');
            } else if (jobType == comConstant.jobType.BOND_SHORT) {
                jobNameArr.push('전단채');
            } else if (jobType == comConstant.jobType.BOND_COMMERCIAL) {
                jobNameArr.push('기업어음');
            } else if (jobType == comConstant.jobType.BOND_BANK) {
                jobNameArr.push('은행예금');
            } else if (jobType == comConstant.jobType.FUND_WELFARE) {
                jobNameArr.push('복지기금');
            } else if (jobType == comConstant.jobType.FUND_SCHOOL) {
                jobNameArr.push('학교기금');
            }
        }
        return jobNameArr.join();
    },
    /**
     * 업무명 표시
     * @param String code 코드
     * @returns 
     */
    printAuthNameArr: (authIdArr) => {
        if (objectUtil.isEmpty(authIdArr)) {
            return '';
        }
        const authNameArr = [];
        for (const authId of authIdArr) {
            if (authId == comConstant.authType.MNG_BOND_POWER) {
                authNameArr.push('발행채권 실무자');
            } else if (authId == comConstant.authType.MNG_FUND_WELFARE) {
                authNameArr.push('복지기금 실무자');
            } else if (authId == comConstant.authType.MNG_FUND_SCHOOL) {
                authNameArr.push('학교기금 실무자');
            } else if (authId == comConstant.authType.ADMIN) {
                authNameArr.push('시스템 관리자');
            }else if (authId == comConstant.authType.READY_UESR) {
                authNameArr.push('미승인 사용자');
            }else if (authId == comConstant.authType.REJECT_USER) {
                authNameArr.push('승인 반려 사용자');
            }
        }
        return authNameArr.join();
    },
    /**
     * 금리유형명 출력
     * @param String 금리유형코드
     * @returns 
     */
    printIntrtTpNm: (intrtTpCd) => {
        let result = '';
        if (intrtTpCd == comConstant.intrtType.FIX) {
            result = '고정금리';
        } else if (intrtTpCd == comConstant.intrtType.FLOATING) {
            result = '연동금리';
        } else if (intrtTpCd == comConstant.intrtType.VARIABLE) {
            result = '변동금리';
        }  else if (intrtTpCd == comConstant.intrtType.SPREAD) {
            result = '스프레드';
        } 
        return result;
    },
    /**
     * 값을 자른 후 리턴
     * @param
     * @returns 
     */
    printSplit: (value, index) => {
        let valueArr = value.split('-');
        return valueArr[index];
    },
    /**
     * 등록일자 
     * @param
     * @returns 
     */
    printFrstRegYmd: (value) => {
        return value.substring(0, 10);
    },
    /**
     * 사용자 상태명
     * @param
     * @returns 
     */
    printUserStatusName: (value) => {
        if (value == comConstant.userStatus.READY
            || value == comConstant.userStatus.APPROVE_WELFARE
            || value == comConstant.userStatus.APPROVE_SCHOOL) {
            return '미승인';
        } else if (value == comConstant.userStatus.APPROVE) {
            return '승인완료';
        } else if (value == comConstant.userStatus.REJECT) {
            return '반려';
        }
        return '';
    },
    /**
     * 핸드폰 번호 뒷 4자리 *로 치환
     * @param
     * @returns 
     */
    mphnNoSplit: (value) => {
        let valueArr = value.split('-');
        let mphnNo = valueArr[0] + '-' + valueArr[1] + "-" + "****" ;
        return mphnNo;
    },
}