/**
 * 기금운용 입찰공고 라우터 설정
 * 
 * @author bjy
 * @since 2023-04-24
 * @version 1.0
 */
export default [
	{
		path: '/bid/fund/bidFundRegist',
		name: 'BidFundRegist',
		component: () => import('@/views/bid/fund/BidFundRegist.vue'),
		meta: { contentTitle: '입찰공고 응찰', menu: 'AnncFundUsr' },
		props: route => route.query
	},
	{
		path: '/bid/fund/bidFundRetryRegist',
		name: 'BidFundRetryRegist',
		component: () => import('@/views/bid/fund/BidFundRetryRegist.vue'),
		meta: { contentTitle: '입찰공고 재응찰', menu: 'AnncFundUsr' },
		props: route => route.query
	},
	{
		path: '/bid/fund/myBidFundList',
		name: 'MyBidFundList',
		component: () => import('@/views/bid/fund/my/MyBidFundList.vue'),
		meta: { contentTitle: '나의 입찰 목록', menu: 'MyBidFund' },
		props: route => route.query
	},
	{
		path: '/bid/fund/myBidFundDetail',
		name: 'MyBidFundDetail',
		component: () => import('@/views/bid/fund/my/MyBidFundDetail.vue'),
		meta: { contentTitle: '나의 입찰 상세', menu: 'MyBidFund' },
		props: route => route.query
	},
];