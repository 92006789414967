/**
 * 기금운용 입찰공고 라우터 설정
 * 
 * @author bjy
 * @since 2023-04-19
 * @version 1.0
 */
export default [
	{
		path: '/annc/fund/anncFundMngList',
		name: 'AnncFundMngList',
		component: () => import('@/views/annc/fund/mng/AnncFundMngList.vue'),
		meta: { contentTitle: '기금운용 공고관리 목록', menu: 'AnncFundMng' }
	},
	{
		path: '/annc/fund/anncFundMngDetail',
		name: 'AnncFundMngDetail',
		component: () => import('@/views/annc/fund/mng/AnncFundMngDetail.vue'),
		meta: { contentTitle: '기금운용 공고관리 상세', menu: 'AnncFundMng' },
		props: (route) => route.query
	},
	{
		path: '/annc/fund/anncFundMngRegist',
		name: 'AnncFundMngRegist',
		component: () => import('@/views/annc/fund/mng/AnncFundMngRegist.vue'),
		meta: { contentTitle: '기금운용 공고관리 등록', menu: 'AnncFundMng' },
		props: (route) => route.query
	},
	{
		path: '/annc/fund/anncFundMngUpdate',
		name: 'AnncFundMngUpdate',
		component: () => import('@/views/annc/fund/mng/AnncFundMngUpdate.vue'),
		meta: { contentTitle: '기금운용 공고관리 정정', menu: 'AnncFundMng' },
		props: (route) => route.query
	},
	{
		path: '/annc/fund/anncFundMngRebidTimeRegist',
		name: 'AnncFundMngRebidTimeRegist',
		component: () => import('@/views/annc/fund/mng/AnncFundRebidTimeRegist.vue'),
		meta: { contentTitle: '재투찰 시간설정', menu: 'AnncFundMng' },
		props: (route) => route.query
	}
];