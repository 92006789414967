<!--
	서브 레이아웃 (컨텐츠)
	
	@author pjg
	@since 2023.03.30
	@version 1.0
-->
<template>
    <!-- skipNav -->
    <div id="skipNav"><a href="#depth1">주메뉴 바로가기</a> <a href="#content_wrap">본문으로 바로가기</a></div>
    <!-- //skipNav -->
    <div id="wrap">
        <ComHeader/>
        <!--content_wrap-->
        <div id="content_wrap" class="sub">
            <!-- <ComLeftMenu/> -->
            <component :is="leftLayout($route)">
                <slot/>
            </component>
            <div id="contents">
                <div class="inner">
                    <slot/>
                </div>
            </div>
        </div>
        <!--//content_wrap-->
        <ComInfo/>
        <ComFooter/>
    </div>
</template>

<script setup>
import '@/assets/css/sub_content.css';
import '@/assets/css/pop_content.css';

import { ref } from 'vue';
import ComHeader from './common/ComHeader.vue';
import ComFooter from './common/ComFooter.vue';
import ComLeftMenu from './common/ComLeftMenu.vue';
import ComInfo from './common/ComInfo.vue';
import MyInfoLeftMenu from './common/MyInfoLeftMenu.vue';

const lookup = { ComLeftMenu, MyInfoLeftMenu };

const leftLayout = ref((route) => {
	if (route.meta.hiddenMenu) {
		return lookup[ 'MyInfoLeftMenu' ];
	} else {
		return lookup[ 'ComLeftMenu' ];
	}
});
</script>