/**
 * 전력채 입찰공고 라우터 설정
 * 
 * @author pjg
 * @since 2023-03-16
 * @version 1.0
 */
export default [
	{
		path: '/annc/bond/anncBondMngList',
		name: 'AnncBondMngList',
		component: () => import('@/views/annc/bond/mng/AnncBondMngList.vue'),
		meta: { contentTitle: '공고관리 목록', menu: 'AnncBondMng' }
	},
	{
		path: '/annc/bond/anncBondMngDetail',
		name: 'AnncBondMngDetail',
		component: () => import('@/views/annc/bond/mng/AnncBondMngDetail.vue'),
		meta: { contentTitle: '공고관리 상세', menu: 'AnncBondMng' },
		props: route => route.query
	},
	{
		path: '/annc/bond/anncBondMngRegist',
		name: 'AnncBondMngRegist',
		component: () => import('@/views/annc/bond/mng/AnncBondMngRegist.vue'),
		meta: { contentTitle: '공고관리 등록', menu: 'AnncBondMng' },
		props: route => route.query
	},
	{
		path: '/annc/bond/anncBondMngUpdate',
		name: 'AnncBondMngUpdate',
		component: () => import('@/views/annc/bond/mng/AnncBondMngUpdate.vue'),
		meta: { contentTitle: '공고관리 정정', menu: 'AnncBondMng' },
		props: route => route.query
	}
];