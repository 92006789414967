import { toRaw } from 'vue';
import { selectAnncBondUsrListApi, selectAnncBondScheduleListApi } from '@/api/anncBondUsrApi';
import { selectAnncFundUsrListApi, selectAnncFundScheduleListApi} from '@/api/anncFundUsrApi';
import { selectBoardListByUrgnNtiApi } from '@/api/boardApi';
import comConstant from '@/common/comConstant';
import { objectUtil } from '@/common/comUtil';


const dateUtil = {
    formattedYmd: (date) => {
        const yyyy = date.getFullYear();
        const mm = (date.getMonth() + 1).toString().padStart(2, '0');
        const dd = (date.getDate()).toString().padStart(2, '0');
        return `${yyyy}${mm}${dd}`;
    }
}

export default {
    /**
     * 차입종류별 전력채 공고 목록 조회
     */
    selectAnncBondList: async(state, key) => {
        const searchParam = toRaw(state.searchParam);
        return selectAnncBondUsrListApi(searchParam)
            .then(res => {
                state.anncListGroups[key] = res.data.resultList;
            });
    },
    /**
     * 공고유형별 기금운용 공고 목록 조회
     */
    selectAnncFundList: async(state, key) => {
        const searchParam = toRaw(state.searchParam);
        return selectAnncFundUsrListApi(searchParam)
            .then(res => {
                state.anncListGroups[key] = res.data.resultList;
            });
    },
    /**
     * 긴급공지 목록 조회
     */
    selselectBoardListByUrgnNti: async(state) => {
        return selectBoardListByUrgnNtiApi({ bltnbId: comConstant.boardType.NOTICE_ID })
            .then(res => {
                state.noticeList = res.data.resultList;
                //state.noticeList.length = 1; // TODO 슬라이드 구현전까지 임시로 1개만 출력되도록 함
            });
    },
    /**
     * 캘린더 날짜(+요일) 배열로 반환
     */
    getCalendarDates: (ym) => {
        ym = new String(ym);

        if (objectUtil.isEmpty(ym) || ym.length != 6) {
            const now = new Date();
            ym = now.getFullYear();
            ym = now.getMonth() + 1;
        }
        
        const searchYYYY = ym.substring(0, 4);
        const searchMM = ym.substring(4, 6);

        const firstDateObj = new Date(searchYYYY, (searchMM>>0) - 1, 1);
        const firstDate = firstDateObj.getDate();
        const firstDay = firstDateObj.getDay();

        const lastDateObj = new Date(searchYYYY, (searchMM>>0), 0);
        const lastDate = lastDateObj.getDate();
        let res = [];

        for (let i = 0, size = (lastDate - firstDate); i <= size; i++) {
            res.push({ date: firstDate + i, day: (firstDay + i) % 7 });
        }

        return res;
    },

     /**
     * 공고데이터 조회
     */
     getCalendarData: async (reactiveValue, anncTpCd, systemType) => {
        const searchParam = {
            startYmd: dateUtil.formattedYmd(new Date(reactiveValue.targetDate.getFullYear(), reactiveValue.targetDate.getMonth(), 1)),
            endYmd: dateUtil.formattedYmd(new Date(reactiveValue.targetDate.getFullYear(), reactiveValue.targetDate.getMonth() + 1, 0)),
            anncTpCd: anncTpCd,
        }

        if(systemType === comConstant.systemType.BOND) {
            await selectAnncBondScheduleListApi(searchParam)
                .then((res) => {
                    reactiveValue.anncList = objectUtil.groupBy(res.data.resultList, 'bidYmdSub');
                });
        } else if(systemType === comConstant.systemType.FUND) {
            await selectAnncFundScheduleListApi(searchParam)
                .then((res) => {
                    reactiveValue.anncList = objectUtil.groupBy(res.data.resultList, 'bidYmdSub');
                });
        }
    },
    
};