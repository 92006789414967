/**
 * 전력채 입찰공고 라우터 설정
 * 
 * @author pjg
 * @since 2023-03-16
 * @version 1.0
 */
export default [
	{
		path: '/bid/bond/bidBondRegist',
		name: 'BidBondRegist',
		component: () => import('@/views/bid/bond/BidBondRegist.vue'),
		meta: { contentTitle: '전력채 응찰', menu: 'AnncBondUsr' },
		props: route => route.query
	},
	{
		path: '/bid/bond/myBidBondList',
		name: 'MyBidBondList',
		component: () => import('@/views/bid/bond/MyBidBondList.vue'),
		meta: { contentTitle: '나의 입찰 목록', menu: 'MyBidBond' },
		props: route => route.query
	},
	{
		path: '/bid/bond/myBidBondDetail',
		name: 'MyBidBondDetail',
		component: () => import('@/views/bid/bond/MyBidBondDetail.vue'),
		meta: { contentTitle: '나의 입찰 상세', menu: 'MyBidBond' },
		props: route => route.query
	}
];