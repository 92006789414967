<template>
    <table class="width-full">
        <colgroup>
            <col width="auto">
            <col width="120px">
            <col width="70px">
        </colgroup>
        <tbody>
            <tr v-if="objectUtil.isEmpty(dataList)">
                <td colspan="3" class="bor-b-no">조회된 공고가 없습니다.</td>
            </tr>
            <tr class="pointer" v-for="data in dataList" :key="data.anncNo" @click="moveDetail(data.anncNo)">
                <td>{{ '[' + (data.brwKndNm || data.anncTpNm) + ']' }} {{ data.anncTitlNm }}</td>
                <td>
                    <span :class="(data.anncStsCd == comConstant.anncStatus.SUCCESS) ? 'progress-2' : 'progress-1'">{{ data.anncStsNm }}</span>
                </td>
                <td>{{ dateUtil.formattedYmd(data.bidYmd) }}</td>
            </tr>
        </tbody>
    </table>
</template>

<script setup>
import { defineProps } from 'vue';
import { dateUtil, objectUtil } from '@/common/comUtil';
import comConstant from '@/common/comConstant';
import { useRouter } from 'vue-router';

const router = useRouter();

const props = defineProps({
    dataList: Array,
    menuNm: String
});

const moveDetail = (anncNo) => {
    router.push({ name: `${props.menuNm}Detail`, query: { anncNo: anncNo } });
};
</script>
