import axiosInstance from "@/api/axiosInstance";

/**
 * 기금운용 입찰공고 목록 조회
 * @param Object searchParam 
 * @returns 
 */
export const selectAnncFundUsrListApi = (searchParam) => {
	return axiosInstance.get('/annc/fund/usr/selectAnncFundUsrList.json', { params: searchParam });
};

/**
 * 기금운용 입찰공고 조회
 * @param Object searchParam 
 * @returns 
 */
export const selectAnncFundUsrApi = (searchParam) => {
	return axiosInstance.get('/annc/fund/usr/selectAnncFundUsr.json', { params: searchParam});
};

/**
 * 기금운용 입찰공고 목록 조회
 * @param Object searchParam 
 * @returns 
 */
export const selectMyAnncFundListApi = (searchParam) => {
	return axiosInstance.get('/annc/fund/usr/selectMyAnncFundList.json', { params: searchParam });
};

/**
 * 기금운용 입찰공고 조회 (나의입찰)
 * @param Object searchParam 
 * @returns 
 */
export const selectMyAnncFundApi = (searchParam) => {
	return axiosInstance.get('/annc/fund/usr/selectMyAnncFund.json', { params: searchParam});
};

/**
 * 기금운용 입찰공고 입찰정보 조회 (나의입찰)
 * @param Object searchParam 
 * @returns 
 */
export const selectMyBidFundListApi = (searchParam) => {
	return axiosInstance.get('/annc/fund/usr/selectMyBidFundList.json', { params: searchParam});
};

/**
 * 기금운용 일정공고 목록 조회
 * @param Object searchParam 
 * @returns 
 */
export const selectAnncFundScheduleListApi = (searchParam) => {
	return axiosInstance.get('/annc/fund/usr/selectAnncFundScheduleList.json', { params: searchParam });
};