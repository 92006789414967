/**
 * 라우터 설정
 * 
 * @author pjg
 * @since 2023-03-16
 * @version 1.0
 */
import { createRouter, createWebHistory } from 'vue-router';
import { nextTick } from 'vue';
import MainView from '@/views/main/MainView.vue';
import anncBondMngRouter from './anncBondMngRouter';
import anncBondUsrRouter from './anncBondUsrRouter';
import anncBondPblsRouter from './anncBondPblsRouter';
import anncFundMngRouter from './anncFundMngRouter';
import anncMngChangeRouter from './anncMngChangeRouter';
import anncExtractRouter from './anncExtractRouter';
import bidBondRouter from './bidBondRouter';
import sampleRouter from './sampleRouter';
import userFindRouter from './userFindRouter';
import userJoinRouter from './userJoinRouter';
import userRestrictRouter from './userRestrictRouter';
import bidBondResultRouter from './bidBondResultRouter';
import sysCodeRouter from './sysCodeRouter';
import sysInfoRouter from './sysInfoRouter';
import userRouter from './userRouter';
import boardRouter from "@/router/boardRouter";
import anncScheduleRouter from "@/router/anncScheduleRouter";
import errorRouter from './errorRouter';
import anncFundUsrRouter from './anncFundUsrRouter';
import bidFundRouter from './bidFundRouter';
import bidFundResultRouter from './bidFundResultRouter';
// import dummyUser from '@/dummy/dummyUser';
import store from '@/store';
// eslint-disable-next-line no-unused-vars
import menuManager from '@/store/modules/menuManager';
import { saveHistAccessApi, saveHistAccessNoLoginApi } from '@/api/historyApi';

const routes = [
	{
		path: '/',
		redirect: '/main'
	},
	{
		path: '/main',
		name: 'Main',
		component: MainView,
		meta: { layout: 'main', contentTitle: '메인' }
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import('@/views/MemberLogin.vue'),
		meta: { layout: 'default', contentTitle: '로그인' }
	},
	{
		path: '/ssoLogin',
		name: 'ssoLogin',
		component: () => import('@/views/ssoLogin.vue'),
		meta: { layout: 'default', contentTitle: 'sso로그인' }
	},
	{
		path: '/siteMap',
		name: 'SiteMap',
		component: () => import('@/views/SiteMap.vue'),
		meta: { layout: 'default', contentTitle: '사이트맵' }
	},
	{
		path: '/footerPrivacy',
		name: 'FooterPrivacy',
		component: () => import('@/views/FooterPrivacy.vue'),
		meta: { layout: 'default', contentTitle: '개인정보처리방침' }
	},
	{
		path: '/footerMail',
		name: 'FooterMail',
		component: () => import('@/views/FooterMail.vue'),
		meta: { layout: 'default', contentTitle: '이메일주소무단수집금지' }
	},
	...anncBondMngRouter,
	...anncBondUsrRouter,
	...anncBondPblsRouter,
	...anncMngChangeRouter,
	...anncExtractRouter,
	...bidBondRouter,
	...sampleRouter,
	...userFindRouter,
	...userJoinRouter,
	...userRestrictRouter,
	...bidBondResultRouter,	
	...sysCodeRouter,
	...sysInfoRouter,
	...userRouter,
	...boardRouter,
	...anncScheduleRouter,
	...anncFundMngRouter,
	...anncFundUsrRouter,
	...bidFundRouter,
	...bidFundResultRouter,
	...errorRouter
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

router.beforeEach(async(to, from) => {
	// 화면 접속 이력 서버에 전달
	const menuList= store.getters.getMenu;
	const isSave = menuList?.find(menu => menu.menuUrlAddr == to.path);
	if (isSave) {
		if (store.getters.isLogin) {
			await saveHistAccessApi({ acsClNm: 'P', acsUrlAddr: to.fullPath });
		} else {
			await saveHistAccessNoLoginApi({ acsClNm: 'P', acsUrlAddr: to.fullPath });
		}
	}

	// 접근권한 체크
	if (!store.getters.hasMenuAccessPermission(to.meta.menu)) {
		return { name: 'ErrorView', query: { errorCode: 'ERR_REFUSE_ACCESS' }};
	}
	
	// 목록화면 검색 조건 초기화
	if (to.meta.menu != from.meta.menu) {
		store.commit('setSearchParam', null);
	}
});

router.afterEach((to) => {	
	nextTick(() => {
		menuManager.initMenuList(store);
		document.title = to.meta.contentTitle || '전력채발행전자입찰시스템';
	});
});

export default router;