<template>
    <nav class="nav-bar" @mouseover="dropdownNav(true)" @mouseleave="dropdownNav(false)" :class="{ open: isOpen }">
        <div class="nav-inner-wrapper text-left">
            <div class="nav-bg" :class="{ open: isOpen }"></div>
            <ul class="depth1" id="depth1" :class="{ open: isOpen }">
                <li v-for="mainMenu in mainMenuList" :key="mainMenu.menuId">
                    <!-- (mainMenu.menuUrlAddr ? mainMenu.menuUrlAddr : '#') -->
                    <router-link :to="{ path: mainMenuMenuUrlAddr(mainMenu) }">{{ mainMenu.menuNm }}</router-link>
                    <ul :class="{ open: isOpen }">
                        <li v-for="subMenu in subMenuList(mainMenu.menuId)" :key="subMenu.menuId">
                            <router-link :to="{ path: (subMenu?.menuUrlAddr ? subMenu?.menuUrlAddr : '#')}">{{ subMenu.menuNm }}</router-link>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </nav>
    
</template>

<script setup>
import { defineProps, computed, ref } from 'vue';
import { objectUtil } from '@/common/comUtil';

const props = defineProps({
    menuList: Array
});

const isOpen = ref(false);

/**
 * 메뉴 목록에서 메인메뉴(대메뉴)만 필터링
 */
const mainMenuList = computed(() => {
    let res = [];

    if (props.menuList) {
        res = props.menuList.filter(menu => objectUtil.isEmpty(menu.uppoMenuId));
    }

    return res;
});

/**
 * 메뉴 목록에서 서브메뉴(메인메뉴별)만 필터링
 */
const subMenuList = (mainMenuId) => {
    let res = [];
    
    if (props.menuList && mainMenuId) {
        res = props.menuList.filter(menu => mainMenuId == menu.uppoMenuId);
    }

    return res;
};

const mainMenuMenuUrlAddr = (mainMenu) => {
    let subMenuList = [];
    let res = mainMenu.menuUrlAddr;
    
    if (props.menuList && mainMenu.menuId) {
        subMenuList = props.menuList.filter(menu => mainMenu.menuId == menu.uppoMenuId);
    }

    // 대메뉴에 지정한 url이 접근권한이 없는 url이 될 수 있을경우
    // SUBMENUINDEX_FOR_URLADDR:index 로 설정하면 
    // 대메뉴의 서브메뉴 중 index에 해당하는 메뉴의 url을 가져옴
    if (/^SUBMENUINDEX_FOR_URLADDR:[0-9]+$/.test(mainMenu.menuUrlAddr) && subMenuList && subMenuList.length > 0) {
        const subMenuIndex = (mainMenu.menuUrlAddr.replace("SUBMENUINDEX_FOR_URLADDR:", ""))>>0;

        if (subMenuIndex <= subMenuList.length) {
            res = subMenuList[subMenuIndex]?.menuUrlAddr;
        }
    }

    if (objectUtil.isEmpty(res)) {
        res = '#';
    }

    return res;
};

/**
 * 메뉴바 토글
 */
const dropdownNav = (open) => {
    isOpen.value = open;
};
</script>
