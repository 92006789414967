export default {
    CONFIRM_SAVE: '저장하시겠습니까?',
    CONFIRM_UPDATE: '수정하시겠습니까?',
    CONFIRM_DELETE: '삭제하시겠습니까?',
    CONFIRM_CANCEL: '취소하시겠습니까?',
    CONFIRM_BIDING: '입찰하시겠습니까?',
    CONFIRM_REVISE: '정정하시겠습니까?',
    CONFIRM_FAIL_BIDING: '해당공고를 유찰시킵니다. 유찰하시겠습니까?',
    SUCCESS_SAVE: '저장되었습니다.',
    SUCCESS_DELETE: '삭제되었습니다.',
    SUCCESS_CANCEL: '취소되었습니다.',
    SUCCESS_BIDING: '입찰되었습니다.',
    SUCCESS_FAIL_BIDING: '유찰되었습니다.',
    SUCCESS_MAIL: '메일이 발송되었습니다.',
    SUCCESS_SMS: '알림톡이 발송되었습니다.',
    NOT_FOUND_INFO: '확인할 수 없습니다.',
    NO_ROWS_ANNC: '등록된 공고가 없습니다.',
    NO_ROWS_ANNC_BIDING: '참여한 공고가 없습니다.',
    NO_ROWS_BID: '등록된 입찰이 없습니다.',
    SUCCESS_CERT_NO_ARR: ['인증 문자를 전송했습니다.', '확인 후 5분 이내 입력해 주세요.'],
    NOT_SEND_CERT_NO: '인증 문자를 전송해 주세요.',
    NOT_STATUS: '가능한 상태가 아닙니다.',
    NOT_AUTH: '권한이 없습니다.',
    RESTRICTED_USER: '입찰제한 대상자입니다',
    CLOSED_BIDING: '입찰이 마감되었습니다.',
    DEFAULT_ERROR: '에러가 발생했습니다.',
    ALLOW_FILE_EXT: '확장자가 gif, png, jpg, jpeg, doc, docx, xls, xlsx, hwp, pdf, pptx, zip 인 파일만 업로드 가능합니다.',
    /**
     * aixos HTTP ERROR 코드 메시지 매핑
     */
    HTTP_STATUS: {
        ERR_NETWORK: '서버에 연결할 수 없습니다. 관리자에게 문의하십시오.',
        ERR_BAD_REQUEST: '요청이 실패했습니다. 관리자에게 문의하십시오.',
        ECONNABORTED: '서버 응답시간이 초과했습니다. 관리자에게 문의하십시오.',
        '403': '접근 권한이 없습니다.',
        '404': '요청이 실패했습니다. 관리자에게 문의하십시오.'
    },
    /**
     * 화면 접근 에러 메시지
     */
    VIEW_ERROR: {
        ERR_NOT_FOUND: '페이지를 찾을 수 없습니다.',
        ERR_REFUSE_ACCESS: '접근할 수 없는 페이지입니다.',
        ERR_BAD_ACCESS: '잘못된 접근입니다.'
    }
};