import axiosInstance from "./axiosInstance";

/**
 * 접속이력저장
 * @param Object registAccessParam
 * @returns
 */
export const saveHistAccessApi = (registAccessParam) => {
	return axiosInstance.post('/history/registHistAccess.json', registAccessParam);
};

/**
 * 접속이력저장 (비회원용)
 * @param Object registAccessParam
 * @returns
 */
export const saveHistAccessNoLoginApi = (registAccessParam) => {
	return axiosInstance.post('/history/registHistAccessByAnonymousUser.json', registAccessParam);
};

/**
 * 개인정보화면조회 이력저장
 * @param Object registAccessParam
 * @returns
 */
export const saveHistPrivateApi = (registPrivateParam) => {
	return axiosInstance.post('/history/registHistPrivate.json', registPrivateParam);
};