<!-- eslint-disable vue/multi-word-component-names -->
<!--
	기본 모달 템플릿
	
	@author pjg
	@since 2023.03.21
	@version 1.0
-->
<template>
    <teleport to='body'>
        <div v-if="isVisible" role="dialog" :id="id">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container" :style="state.containerCss" :class="wrapClass">
                        <div v-if="!headerHide" :class="objectUtil.notEmpty(headerClass) ? headerClass : 'modal-header'">
                            <slot name="header"/>
                        </div>
                        <div :style="state.bodyCss" :class="objectUtil.notEmpty(bodyClass) ? modal-body : 'modal-body'">
                            <slot name="body"/>
                        </div>
                        <div v-if="!footerHide" :class="objectUtil.notEmpty(footerClass) ? footerClass : 'modal-footer'">
                            <slot name="footer">
                                <button type="button" class="modal-default-button" @click="close">확인</button>
                            </slot>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </teleport>
</template>

<script setup>
import { defineExpose, defineProps, reactive, ref } from 'vue';
import { objectUtil } from '@/common/comUtil';

const props = defineProps({
    width: { type: String, default: '300px' },
    height: { type: String, default: '' },
    marign: { type: String, default: '0 auto' },
    padding: { type: String, default: '20px 30px' },
    backgroundColor: { type: String, default: '#fff' },
    bodyMargin: { type: String, default: '20px 0'},
    bodyWidth: { type: String, default: '' },
    bodyHeight: { type: String, default: '' },
    bodyBackgroundColor: { type: String, default: '' },
    wrapClass: { type: String, default: '' },
    headerClass: { type: String, default: '' },
    bodyClass: { type: String, default: '' },
    footerClass: { type: String, default: '' },
    headerHide: { type: Boolean, default: false },
    footerHide: { type: Boolean, default: false },
    id: { type: String, default: 'modal-template' }
});

const state = reactive({
    containerCss: {
        width: props.width,
        height: props.height,
        margin: props.margin,
        padding: props.padding,
        backgroundColor: props.backgroundColor
    },
    bodyCss: {
        width: props.bodyWidth,
        height: props.bodyHeight,
        margin: props.bodyMargin
    }
});

const isVisible = ref(false);

/**
 * 모달 열기
 */
const open = () => {
    isVisible.value = true;
};

/**
 * 모달 닫기
 */
const close = () => {
    isVisible.value = false;
};

/**
 * 외부 노출
 */
defineExpose({
    open,
    close
});
</script>

<style scoped>
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.modal-container {
    margin: 0 auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
}

.modal-header h3 {
    margin-top: 0;
    color: #42b983;
}

.modal-body {
    margin: 20px 0;
}

.modal-footer::after {
    content: " "; 
    display: table; 
    clear: both;
}

.modal-default-button {
    width: 76px;
    line-height: 29px;
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    text-align: center;
    display: block;
    float: right;
    margin-right: 7px;
    color: #848484;
    font-size: 14px;
}
</style>