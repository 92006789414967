/**
 * 공고이전 목록 라우터 설정
 * 
 * @author jjw
 * @since 2023-05-10
 * @version 1.0
 */
export default [
	{
		path: '/annc/change/anncMngChangeList',
		name: 'AnncMngChangeList',
		component: () => import('@/views/annc/change/AnncMngChangeList.vue'),
		meta: { contentTitle: '공고이전 목록', menu: 'AnncChange' }
	}
];