/**
 * 전력채 입찰결과
 * 
 * @author jsh
 * @since 2023-03-27
 * @version 1.0
 */
export default [
	{
		path: '/bid/bond/result/bidBondProgress',
		name: 'BidBondProgress',
		component: () => import('@/views/bid/bond/result/BidBondProgress.vue'),
		meta: { contentTitle: '입찰진행현황', menu: 'AnncBondMng' },
		props: (route) => ({ anncNo: route.query.anncNo })
	},
	{
		path: '/bid/bond/result/bidBondJoinListPop',
		name: 'BidBondJoinListPop',
		component: () => import('@/views/bid/bond/result/BidBondJoinListPop.vue'),
		meta: { contentTitle: '입찰상세내역', menu: 'AnncBondMng'  },
		props: (route) => ({ anncNo: route.query.anncNo })
	},
	{
		path: '/bid/bond/result/bidBondTotal',
		name: 'BidBondTotal',
		component: () => import('@/views/bid/bond/result/BidBondTotal.vue'),
		meta: { contentTitle: '입찰종합표', menu: 'AnncBondMng'  },
		props: (route) => route.query
	},
	{
		path: '/bid/bond/result/bidBondSuccessRegist',
		name: 'BidBondSuccessRegist',
		component: () => import('@/views/bid/bond/result/BidBondSuccessRegist.vue'),
		meta: { contentTitle: '최종입찰결과공고', menu: 'AnncBondMng'  },
		props: (route) => route.query
	},
	{
		path: '/bid/bond/result/bidBondSuccessDetail',
		name: 'BidBondSuccessDetail',
		component: () => import('@/views/bid/bond/result/BidBondSuccessDetail.vue'),
		meta: { contentTitle: '낙찰확인', menu: 'AnncBondMng'  },
		props: (route) => ({ anncNo: route.query.anncNo })
	},
	{
		path: '/bid/bond/result/bidBondReceiptListPop',
		name: 'BidBondReceiptListPop',
		component: () => import('@/views/bid/bond/result/BidBondReceiptListPop.vue'),
		meta: { contentTitle: '접수현황조회', menu: 'AnncBondMng'  },
		props: (route) => ({ anncNo: route.query.anncNo })
	}
];