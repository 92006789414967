import comConstant from "@/common/comConstant";

/**
 * 게시판 라우터 설정
 *
 * @author pjg
 * @since 2023-03-23
 * @version 1.0
 */
export default [
	{
		path: '/board/notice/list',
		name: 'NoticeList',
		component: () => import('@/views/board/notice/NoticeList.vue'),
		meta: { contentTitle: `공지사항 목록`, menu: 'Notice' },
		props: (route) => ({
			bltnbId: comConstant.boardType.NOTICE_ID,
			...route.query
		})
	},
	{
		path: '/board/notice/regist',
		name: 'NoticeRegist',
		component: () => import('@/views/board/notice/NoticeRegist.vue'),
		meta: { contentTitle: `공지사항 등록`, menu: 'Notice' },
		props: (route) => ({
			bltnbId: comConstant.boardType.NOTICE_ID, 
			...route.query
		})
	},
	{
		path: '/board/notice/update',
		name: 'NoticeUpdate',
		component: () => import('@/views/board/notice/NoticeUpdate.vue'),
		meta: { contentTitle: `공지사항 수정`, menu: 'Notice' },
		props: (route) => ({
			bltnbId: comConstant.boardType.NOTICE_ID, 
			...route.query
		})
	},
	{
		path: '/board/notice/detail',
		name: 'NoticeDetail',
		component: () => import('@/views/board/notice/NoticeDetail.vue'),
		meta: { contentTitle: `공지사항 상세`, menu: 'Notice' },
		props: (route) => ({
			pstNo: route.query.pstNo,
			bltnbId: comConstant.boardType.NOTICE_ID, 
		})
	},
	{
		path: '/board/qna/list',
		name: 'QnaList',
		component: () => import('@/views/board/qna/QnaList.vue'),
		meta: { contentTitle: `질의응답 목록`, menu: 'Qna' },
		props: () => ({
			bltnbId: comConstant.boardType.QNA_ID
		})
	},
	{
		path: '/board/qna/regist',
		name: 'QnaRegist',
		component: () => import('@/views/board/qna/QnaRegist.vue'),
		meta: { contentTitle: `질의응답 등록`, menu: 'Qna' },
		props: (route) => ({
			bltnbId: comConstant.boardType.QNA_ID, 
			...route.query
		})
	},
	{
		path: '/board/qna/update',
		name: 'QnaUpdate',
		component: () => import('@/views/board/qna/QnaUpdate.vue'),
		meta: { contentTitle: `질의응답 수정`, menu: 'Qna' },
		props: (route) => ({
			bltnbId: comConstant.boardType.QNA_ID, 
			...route.query
		})
	},
	{
		path: '/board/qna/detail',
		name: 'QnaDetail',
		component: () => import('@/views/board/qna/QnaDetail.vue'),
		meta: { contentTitle: `질의응답 상세`, menu: 'Qna' },
		props: (route) => ({
			bltnbId: comConstant.boardType.QNA_ID, 
			...route.query
		})
	},
];
