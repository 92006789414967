/**
 * 시스템관리 라우터 설정
 *
 * @author pjg
 * @since 2023-03-23
 * @version 1.0
 */
export default [
	{
		path: '/code/list',
		name: 'CodeGroupList',
		component: () => import('@/views/sys/code/CodeGroupList.vue'),
		meta: { contentTitle: '공통코드 목록', menu: 'Code' },
	},
	{
		path: '/code/group/detail',
		name: 'CodeGroupDetail',
		component: () => import('@/views/sys/code/CodeGroupDetail.vue'),
		meta: { contentTitle: '공통그룹코드 상세', menu: 'Code' },
		props: (route) => route.query
	},
	{
		path: '/code/group/regist',
		name: 'CodeGroupRegist',
		component: () => import('@/views/sys/code/CodeGroupRegist.vue'),
		meta: { contentTitle: '공통그룹코드 등록', menu: 'Code' },
	},
	{
		path: '/code/group/update',
		name: 'CodeGroupUpdate',
		component: () => import('@/views/sys/code/CodeGroupUpdate.vue'),
		meta: { contentTitle: '공통그룹코드 수정', menu: 'Code' },
		props: (route) => route.query
	},
	{
		path: '/code/regist',
		name: 'CodeRegist',
		component: () => import('@/views/sys/code/CodeRegist.vue'),
		meta: { contentTitle: '공통코드 등록', menu: 'Code' },
		props: (route) => route.query
	},
	{
		path: '/code/update',
		name: 'CodeUpdate',
		component: () => import('@/views/sys/code/CodeUpdate.vue'),
		meta: { contentTitle: '공통코드 수정', menu: 'Code' },
		props: (route) => route.query
	},
	{
		path: '/code/detail',
		name: 'CodeDetail',
		component: () => import('@/views/sys/code/CodeDetail.vue'),
		meta: { contentTitle: '공통코드 상세', menu: 'Code' },
		props: (route) => route.query
	},
];
