<template>
    <div class="notice-slide">
        <div class="box-wrapper">
            <div class="bx-viewport" aria-live="polite">
                <ul class="bxslider" :style="state.updatedCss">
                    <li v-if="objectUtil.isEmpty(noticeList)">
                        <a href="#" class="cursor-text">조회된 공지가 없습니다.</a>
                    </li>
                    <!-- copy last item -->
                    <li v-if="objectUtil.notEmpty(noticeList) && noticeList?.length > 0" aria-hidden="true">
                        <router-link :to="{ name: 'NoticeDetail', query: { pstNo: noticeList[noticeList.length-1].pstNo }}">{{ '[공지] ' + noticeList[noticeList.length-1].pstTitlNm }}</router-link>
                    </li>
                    <!-- all items -->
                    <li v-for="(notice, i) in noticeList" :key="`notice-${i}`" :aria-hidden="(state.currentIdx != (i+1))">
                        <router-link :to="{ name: 'NoticeDetail', query: { pstNo: notice.pstNo }}">{{ '[공지] ' + notice.pstTitlNm }}</router-link>
                    </li>
                    <!-- copy first item -->
                    <li v-if="objectUtil.notEmpty(noticeList)" aria-hidden="true">
                        <router-link :to="{ name: 'NoticeDetail', query: { pstNo: noticeList[0].pstNo }}">{{ '[공지] ' + noticeList[0].pstTitlNm }}</router-link>
                    </li>
                </ul>
            </div>
            <div class="bx-controls bx-has-controls-direction">
                <div class="bx-controls-direction">
                    <button class="bx-prev" @click="handlePrev">Prev</button>
                    <button class="bx-next" @click="handleNext">Next</button>
                </div>
            </div>
        </div>
        <router-link class="more-btn" :to="{ name: 'NoticeList' }"></router-link>
    </div>
</template>

<script setup>
import { defineProps, defineExpose, reactive, onMounted, onUnmounted } from 'vue';
import { objectUtil } from '@/common/comUtil';

const CONTENT_HEIGHT = 22;

const props = defineProps({
    noticeList: Array,
    pause: { type: Number, default: 3 },
    speed: { type: Number, default: 0.5 },
    auto: { type: Boolean, default: true }
});

const state = reactive({
    updatedCss: 'transition-duration: 0.0s; transform: translate3d(0px, -21px, 0px);',
    currentIdx: 1,
    runInterval: null,
    isRun: false
});

const start = () => {

    // 1개 이하는 autoRun 실행 하지 않음
    if (props.noticeList?.length <= 1) {
        return false;
    }

    const nextDelay = props.pause * 1000;
    state.isRun = true;

    setTimeout(() => {
        autoRun();
    }, nextDelay);
};

const releaseAutoRun = () => {
    clearInterval(state.runInterval);
    state.runInterval = null;
};

const autoRun = () => {
    if (state.runInterval != null) {
        releaseAutoRun();
    }

    if (state.isRun) {
        handleNext();
        const nextDelay = props.pause * 1000 + props.speed * 1000;
        state.runInterval = setTimeout(() => { autoRun(); }, nextDelay);
    }
};

const handlePrev = () => {
    // 1개 이하는 autoRun 실행 하지 않음
    if (props.noticeList?.length <= 1) {
        return false;
    }
    
    state.currentIdx--;
    const ty = state.currentIdx * (CONTENT_HEIGHT * -1);
    state.updatedCss = `transition-duration: ${props.speed}s; transform: translate3d(0px, ${ty}px, 0px);`;

    if (state.currentIdx <= 0) {
        state.currentIdx = props.noticeList?.length;
        fiexedRealPos();
    }
};

const handleNext = () => {
    // 1개 이하는 autoRun 실행 하지 않음
    if (props.noticeList?.length <= 1) {
        return false;
    }
    
    state.currentIdx++;
    const ty = state.currentIdx * (CONTENT_HEIGHT * -1);
    state.updatedCss = `transition-duration: ${props.speed}s; transform: translate3d(0px, ${ty}px, 0px);`;

    if (state.currentIdx >= (props.noticeList?.length + 1)) {
        state.currentIdx = 1;
        fiexedRealPos(1);
    }
};

const fiexedRealPos = () => {
    setTimeout(() => {
        const ty = state.currentIdx * (CONTENT_HEIGHT * -1);
        state.updatedCss = `transition-duration: 0.0s; transform: translate3d(0px, ${ty}px, 0px);`;
    }, (props.speed * 1000));
};

onMounted(() => {
    releaseAutoRun();
});

onUnmounted(() => {
    releaseAutoRun();
    state.isRun = false;
});

defineExpose({
    start
});
</script>

<style scoped>
.box-wrapper {
    max-width: 100%;
}

.bx-viewport {
    width: 100%; 
    overflow: hidden; 
    position: relative; 
    height: 22px;
}

.bxslider {
    width: auto; 
    position: relative; 
}

.notice-slide .bx-controls-direction a,
.notice-slide .bx-controls-direction button {
    text-indent: -9999px;
    display: block;
    width: 26px;
    height: 26px;
    border: 1px solid #d7d7d7;
    border-radius: 50%;
    position: absolute;
    top: 26px;
}

.notice-slide .bx-controls-direction .bx-prev {
    background: url(~@/assets/images/lnb_arr.png) no-repeat center;
    right: 97px;
    transform: rotate(180deg);
}

.notice-slide .bx-controls-direction .bx-next {
    background: url(~@/assets/images/lnb_arr.png) no-repeat center;
    right: 62px;
}
</style>