<!--
	최상단 현재시간 출력
	
	@author pjg
	@since 2023.03.21
	@version 1.0
-->
<template>
    <p class="severTime width-250"><span>서버시간 : </span>{{ state.serverClcok }}</p>
</template>

<script setup>
import { onMounted, onBeforeUnmount, reactive } from 'vue';
import { getNowDateTimeApi } from '@/api/commonApi';
import { dateUtil, objectUtil } from '@/common/comUtil';
import { useStore } from 'vuex';

const store = useStore();

const state = reactive({
    diffTime: 0,
    serverClcok: '',
    clockInterval: null,
    isRun: true
});

let serverDateObj = null;

/**
 * 서버시간 호출 -> 시간표시 함수 호출
 */
const getServerDateTime = async() => {
    await getNowDateTimeApi()
        .then((res) => {
            serverDateObj = dateUtil.strToDateObj(res.data.result);
            //const frontDateObj = new Date();
            state.diffTime = 0;
            store.commit('setServerTime', { diffTime: state.diffTime });
            runClock();
        })
        .catch(() => {
            state.diffTime = 0; // error 일 경우 front 시간을 기준으로 함
        });
};

/**
 * 서버시계 동작
 * 1초마다 서버시간 받아서 출력 / 못 받으면 클라이언트 시간으로 출력
 */
const runClock = async() => {
    const dateObj = new Date(serverDateObj.getTime() + state.diffTime);
    dateObj.setSeconds(dateObj.getSeconds() + state.diffTime);

    if (state.clockInterval != null) {
        releaseClock();
    }

    const year = objectUtil.zeroFill(dateObj.getFullYear(), 2);
    const month = objectUtil.zeroFill(dateObj.getMonth() + 1, 2);
    const day = objectUtil.zeroFill(dateObj.getDate(), 2);
    const hour = objectUtil.zeroFill(dateObj.getHours(), 2);
    const minute = objectUtil.zeroFill(dateObj.getMinutes(), 2);
    const second = objectUtil.zeroFill(dateObj.getSeconds(), 2);

    state.serverClcok = `${year}년 ${month}월 ${day}일 ${hour}:${minute}:${second}`;

    ++state.diffTime;
    if (state.isRun) {
        state.clockInterval = setTimeout(runClock, 1000);
    }
};

/**
 * runClock 사용한 setTimeout 해제
 */
const releaseClock = () => {
    clearInterval(state.clockInterval);
    state.clockInterval = null;
}

onMounted(() => {
    getServerDateTime();
});

onBeforeUnmount(() => {
    // 갱신 완전 종료 시킴
    state.isRun = false;
});
</script>
